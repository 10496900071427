import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import { arrowUpRightIcon, avatar1Icon, googleIcon } from 'resources/images';
import { sendUserLoginOtpApi } from 'networking/api/auth';
import { useStrings } from 'providers/stringsprovider';
import routePaths from 'routes/paths';
import Input from 'components/common/input';
import Button from 'components/common/button';
import Image from 'components/common/image';
import Loader from 'components/common/loader';
import { useAuth } from 'providers/authprovider';
import { useToast } from 'providers/toastprovider';
import styles from './style.module.css';

const Login = () => {
  const { strings } = useStrings();
  const toast = useToast();
  const navigate = useNavigate();
  const { authError, user, setIsLoggedIn, setCompany, setAuthError, userLogin } = useAuth();
  const [actionLoading, setActionLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [schemaObj, setSchemaObj] = useState({
    email: Yup.string().email(strings.validEmail).required(strings.emailRequired),
  });

  useEffect(() => {
    setAuthError(null);
  }, [setAuthError]);

  useEffect(() => {
    if (step === 1) {
      setSchemaObj({
        email: Yup.string()
          .email(strings.validEmail)
          .matches(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, strings.validEmail)
          .required(strings.emailRequired),
      });
    }
    if (step === 2) {
      setSchemaObj({
        ...schemaObj,
        otp: Yup.string().required(strings.otpRequired),
      });
    }
    // eslint-disable-next-line
  }, [step, strings.validEmail, strings.emailRequired, strings.otpRequired]);

  const signInSchema = Yup.object().shape(schemaObj);

  const handleLogin = async (values, { resetForm }) => {
    try {
      if (step === 1) {
        sendLoginOtp(values);
      } else if (step === 2) {
        setActionLoading(true);
        await userLogin(values);
        setActionLoading(false);
      }
    } catch (error) {
      setActionLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      otp: '',
    },
    validationSchema: signInSchema,
    onSubmit: handleLogin,
  });

  const sendLoginOtp = async (data) => {
    try {
      let otpParamsData = {
        email: data.email,
      };
      setActionLoading(true);
      let response = await sendUserLoginOtpApi(otpParamsData);
      if (response.data.type === 'success') {
        console.log('send Login Otp Api response', response.data.data);
        setStep(2);
        formik.setTouched({});
        setActionLoading(false);
      } else {
        setAuthError(response.data.message);
        setActionLoading(false);
      }
    } catch (error) {
      setActionLoading(false);
      setAuthError(error.message);
    }
  };

  const resendOtp = async () => {
    await sendLoginOtp(formik.values);
    toast.success('OTP sent successfully');
  };

  const tapOnCompany = async (companyData) => {
    setIsLoggedIn(true);
    localStorage.setItem('company', companyData._id);
    setCompany(companyData);
  };

  const renderPagination = () => {
    return (
      <div className={styles.paginationContainerStyle}>
        {Array.from({ length: 2 }, (_, i) => (
          <div
            key={'pagination' + i + 1}
            className={step === i + 1 ? styles.activeDotBtnStyle : styles.dotBtnStyle}
          />
        ))}
      </div>
    );
  };

  const renderLoginFormSection = () => {
    return (
      <div className={styles.authBlockStyle}>
        {renderPagination()}
        <form className={styles.loginWrapperStyle} onSubmit={formik.handleSubmit}>
          {step === 1 && (
            <React.Fragment>
              <p className={styles.loginWrapperTitleStyle}>Log in to your trakr account.</p>
              <Input
                type="email"
                name="email"
                labelText={strings.emailLabel}
                inputLabelStyle={styles.inputLabelStyle}
                placeholder={strings.enterEmailId}
                value={formik.values.email}
                onChange={(e) => {
                  formik.handleChange(e);
                  setAuthError('');
                }}
                onBlur={formik.handleBlur}
                onFocus={() => formik.setFieldError('email', '')}
                hasError={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
              />
            </React.Fragment>
          )}
          {step === 2 && (
            <React.Fragment>
              <p
                className={styles.loginWrapperTitleStyle}
              >{`We sent an OTP to ${formik.values.email}`}</p>
              <div className={styles.otpSectionWrapperStyle}>
                <Input
                  type="text"
                  name="otp"
                  labelText={strings.enterOtpLabel}
                  inputLabelStyle={styles.inputLabelStyle}
                  placeholder={strings.otpPlaceHolder}
                  value={formik.values.otp}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setAuthError('');
                  }}
                  onBlur={formik.handleBlur}
                  onFocus={() => formik.setFieldError('otp', '')}
                  hasError={formik.touched.otp && formik.errors.otp ? formik.errors.otp : ''}
                />
                <p className={styles.resendEditEmailTextWrapperStyle}>
                  <span onClick={() => resendOtp()}>Resend</span>or{' '}
                  <span
                    onClick={() => {
                      setStep(1);
                      formik.setFieldValue('otp', '');
                    }}
                  >
                    edit email
                  </span>
                </p>
              </div>
            </React.Fragment>
          )}
          <div className={styles.loginBtnWrapperStyle}>
            {authError && <p className={styles.errorTextStyle}>{authError}</p>}
            {step === 1 && (
              <React.Fragment>
                <Button
                  title={actionLoading ? 'Loading' : 'Proceed'}
                  type="submit"
                  btnStyle={styles.proceedBtnStyles}
                  disabled={actionLoading}
                >
                  {actionLoading && <Loader loaderStyle={styles.loaderStyle} />}
                </Button>
                <Button
                  title="Login with Google"
                  btnStyle={styles.googleBtnStyles}
                  leftIcon={googleIcon}
                  leftIconStyle={styles.googleIconStyle}
                />
              </React.Fragment>
            )}
            {step === 2 && (
              <Button
                title={actionLoading ? 'Loading' : 'Dive in'}
                type="submit"
                btnStyle={styles.proceedBtnStyles}
                disabled={actionLoading}
              />
            )}
          </div>
          <p className={styles.signupTextStyle}>
            {strings.haveNoAccount}
            <span onClick={() => navigate(routePaths.signup)}>{strings.signup}</span>
          </p>
        </form>
      </div>
    );
  };

  const renderUserCompaniesSection = () => {
    return (
      <div className={styles.loginWrapperStyle}>
        <p className={styles.loginWrapperTitleStyle}>{user?.full_name}, good to see you again.</p>
        <div className={styles.userProfleStyle}>
          <Image src={avatar1Icon} containerStyle={styles.userIconStyle} />
          <div className={styles.userInfoStyle}>
            <p className={styles.userTitleStyle}>{user?.full_name}</p>
            <p className={styles.userDescStyle}>{user?.email}</p>
          </div>
        </div>
        <div className={styles.userCompaniesWrapperStyle}>
          {user?.companies.map((company, index) => {
            return (
              <Button
                key={'com' + index}
                title={`Open ${company.company_name}'s account`}
                rightIcon={arrowUpRightIcon}
                btnStyle={styles.companyBtnStyle}
                rightIconStyle={styles.companyBtnIconStyle}
                onClick={() => tapOnCompany(company)}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <AuthLayout>
      {user?.companies.length > 1 ? renderUserCompaniesSection() : renderLoginFormSection()}
    </AuthLayout>
  );
};

export default Login;
