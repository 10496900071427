import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import {
  chevronDownFillBlackIcon,
  chevronDownFillBlueIcon,
  chevronLeftBlackIcon,
  optionsVerticalGrayIcon,
  xlsBlueIcon,
} from 'resources/images';
import Image from 'components/common/image';
import {
  archiveClusterApi,
  fetchtClusterCommentsApi,
  getSelectedClustersDetailsApi,
  inviteClusterMembersAPi,
  postClusterCommentApi,
  updateClusterAccessApi,
} from 'networking/api/cluster';
import Loader from 'components/common/loader';
import AvatarGroup from 'components/common/avatar-group';
import routePaths from 'routes/paths';
import Capsule from 'components/common/capsule';
import ClusterReport from 'sections/maintenance/cluster-report';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Modal from 'components/common/modal';
import ClusterCreate from '../cluster-create';
import PopOver from 'components/common/pop-over';
import Avatar from 'components/common/avatar';
import { account_types, clusterMenuOptions, reportsSortOptions } from 'resources/data';
import Button from 'components/common/button';
import ClusterInfo from '../cluster-info';
import Members from 'components/members';
import { useStrings } from 'providers/stringsprovider';
import { useUserData } from 'providers/userdataprovider';
import { socket } from 'providers/socketprovider';
import { useAppData } from 'providers/appdataprovider';
import classNames from 'classnames';
import { useAuth } from 'providers/authprovider';
import moment from 'moment';
import DatePicker from 'components/common/date-picker';
import ChatInput from 'components/common/chat-input';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const ClusterDetails = (props) => {
  const { id, setSortClusterBy } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const { setShowHeader } = useAppData();
  const { user } = useAuth();
  const { setClusters, clusters } = useUserData();
  const { width } = useWindowDimensions();
  const { strings } = useStrings();
  const [sortPopupRef, setSortPopupRef] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cluster, setCluster] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Ongoing');
  const [clusterReports, setClusterReports] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState('');
  const [showEditClusterModal, setShowEditClusterModal] = useState(false);
  const [menuModal, setMenuModal] = useState(false);
  const [menuRef, setMenuRef] = useState(null);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [archiveLoader, setArchiveLoader] = useState(false);
  const [clusterInfoModal, setClusterInfoModal] = useState(false);
  const [isMembersModal, setIsMembersModal] = useState(false);
  const [membersRef, setMembersRef] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState(strings.canView);
  const [membersLoader, setMembersLoader] = useState(false);
  const [hasAccess, setHssAccess] = useState(false);
  const [sortReportsPopUp, setSortReportsPopup] = useState(false);
  const [sortReportsByDate, setSortReportsByDate] = useState(false);
  const [sortReportsBy, setSortReportsBy] = useState('All reports');
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [showComments, setShowComments] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [conversationsLimit, setConversationsLimit] = useState(3);
  const [message, setMessage] = useState('');
  const [selectedThread, setSelectedThread] = useState('');
  const [disableSendBtn, setDisableSendBtn] = useState(false);
  const clusterTabs = ['Ongoing', 'Past reports'];

  useEffect(() => {
    if (id) {
      getClusterData();
      setSelectedTab('Ongoing');
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (cluster) {
      let mem = cluster?.users.find((mem) => mem.user._id === user?._id);
      if (mem.user.account_type === account_types.super_admin) {
        setHssAccess(true);
      } else {
        if (mem) {
          if (mem.permissions.includes('Edit') && user?.account_type === account_types.admin) {
            setHssAccess(true);
          } else {
            setHssAccess(false);
          }
        }
      }
    }
  }, [cluster]);

  useEffect(() => {
    if (width <= 480) {
      setShowHeader(false);
    }
  }, [width]);

  useEffect(() => {
    if (cluster) {
      if (selectedTab === 'Ongoing') {
        setClusterReports(cluster.ongoing_report ? [cluster.ongoing_report] : []);
      } else {
        setClusterReports(cluster.past_reports);
      }
    } else {
      setClusterReports([]);
    }
  }, [selectedTab, cluster]);

  useEffect(() => {
    if (selectedTab === 'Ongoing' && clusterReports.length > 0) {
      setSelectedReportId(clusterReports[0]._id);
    }
  }, [selectedTab, clusterReports]);

  useEffect(() => {
    if (selectedTab === 'Ongoing' && selectedReportId) {
      fetchComments();
    }
  }, [selectedTab, selectedReportId]);

  useEffect(() => {
    socketListner();
    // eslint-disable-next-line
  }, []);

  const socketListner = () => {
    socket.on('cluster-update', (data) => {
      setCluster((prev) => {
        let previous_data = { ...prev };
        if (previous_data && previous_data._id === data._id) {
          if (data.type !== 'Custom') {
            return {
              ...prev,
              ...data,
              recurrence: null,
            };
          } else {
            return {
              ...prev,
              ...data,
            };
          }
        }
      });
    });
    // cluster archive
    socket.on('cluster-archive', (data) => {
      setCluster((prev) => {
        let previous_data = { ...prev };
        if (previous_data && previous_data._id === data._id) {
          return {
            ...previous_data,
            archived_at: data.archived_at,
          };
        } else {
          return previous_data;
        }
      });
    });
    //  cluster users invite
    socket.on('cluster-users-invite', (data) => {
      setCluster((prev) => {
        let previous_data = { ...prev };
        if (previous_data && previous_data._id === data._id) {
          return { ...previous_data, ...data };
        } else {
          return previous_data;
        }
      });
    });
    //  cluster users access
    socket.on('cluster-users-access', (data) => {
      setCluster((prev) => {
        let previous_data = { ...prev };
        if (previous_data && previous_data._id === data._id) {
          return { ...previous_data, ...data };
        } else {
          return previous_data;
        }
      });
    });
    // inspect cluster
    socket.on('cluster-inspect', (data) => {
      setCluster((prev) => {
        let previous_data = { ...prev };
        if (previous_data?.ongoing_report && previous_data?.ongoing_report?._id === data._id) {
          previous_data = { ...previous_data, ongoing_report: data };
        }
        return previous_data;
      });
    });
    //  cluster complaint create
    socket.on('cluster-complaint-create', (data) => {
      // If the complaint is related to cluster
      if (data.mcluster) {
        setCluster((prev) => {
          if (prev) {
            let previous_data = { ...prev };
            if (previous_data._id === data.mcluster) {
              if (previous_data.ongoing_report) {
                let assetReportIndex = previous_data.ongoing_report.cluster_assets.findIndex(
                  (ca) => ca.asset._id === data.asset
                );
                if (assetReportIndex !== -1) {
                  if (data.checklist_item) {
                    let checklists =
                      previous_data.ongoing_report.cluster_assets[assetReportIndex].checklist || [];
                    let outerIndex = -1;
                    let innerIndex = -1;
                    for (let i = 0; i < checklists.length; i++) {
                      for (let j = 0; j < checklists[i].items.length; j++) {
                        if (checklists[i].items[j]._id === data.checklist_item) {
                          outerIndex = i;
                          innerIndex = j;
                        }
                      }
                    }
                    if (outerIndex !== -1 && innerIndex !== -1) {
                      previous_data.ongoing_report.cluster_assets[assetReportIndex].checklist[
                        outerIndex
                      ].items[innerIndex].complaint = data;
                    }
                  } else {
                    let isExits = previous_data.ongoing_report.cluster_assets[
                      assetReportIndex
                    ].complaints.find((com) => com._id === data._id);
                    if (!isExits) {
                      previous_data.ongoing_report.cluster_assets[assetReportIndex].complaints = [
                        ...previous_data.ongoing_report.cluster_assets[assetReportIndex].complaints,
                        data,
                      ];
                    }
                  }
                  if (data.on_hold) {
                    previous_data.ongoing_report.cluster_assets[assetReportIndex].on_hold =
                      data.on_hold;
                  }
                }
              }
              return previous_data;
            } else {
              return previous_data;
            }
          }
          return prev;
        });
        setClusters((prev) => {
          let clustersData = [...prev];
          let findClsIndex = clustersData.findIndex((item) => item._id === data.mcluster);
          // find asset index
          if (findClsIndex !== -1) {
            let assetIndex = clustersData[findClsIndex]?.last_report?.cluster_assets.findIndex(
              (ast) => ast.asset === data.asset
            );
            /// find asset checklist index
            if (assetIndex !== -1) {
              if (data.checklist_item) {
                let checklists =
                  clustersData[findClsIndex].last_report?.cluster_assets[assetIndex]?.checklist ||
                  [];
                let outerIndex = -1;
                let innerIndex = -1;
                for (let i = 0; i < checklists.length; i++) {
                  for (let j = 0; j < checklists[i].items.length; j++) {
                    if (checklists[i].items[j]._id === data.checklist_item) {
                      outerIndex = i;
                      innerIndex = j;
                    }
                  }
                }
                if (outerIndex !== -1 && innerIndex !== -1) {
                  clustersData[findClsIndex].last_report.cluster_assets[assetIndex].checklist[
                    outerIndex
                  ].items[innerIndex].complaint = data;
                }
              } else {
                clustersData[findClsIndex].last_report.cluster_assets[assetIndex].complaints = [
                  ...clustersData[findClsIndex].last_report?.cluster_assets[assetIndex].complaints,
                  data,
                ];
              }
              clustersData[findClsIndex].last_report.cluster_assets[assetIndex].on_hold =
                data?.on_hold;
            }
          }
          return clustersData;
        });
      }
    });
    // cluster asset unhold
    socket.on('cluster-asset-unhold', (data) => {
      setCluster((prev) => {
        let previous_data = { ...prev };
        if (previous_data?.ongoing_report) {
          let asset_index = previous_data.ongoing_report.cluster_assets.findIndex(
            (item) => item.asset._id === data.asset
          );
          if (asset_index !== -1) {
            previous_data.ongoing_report.cluster_assets[asset_index].on_hold = undefined;
          }
        }
        if (previous_data.cluster_assets.length > 0) {
          let asset_index = previous_data.cluster_assets.findIndex(
            (item) => item.asset._id === data.asset
          );
          if (asset_index !== -1) {
            previous_data.cluster_assets[asset_index].on_hold = undefined;
          }
        }
        return previous_data;
      });
    });
    socket.on('cluster-comment-create', (data) => {
      setClusterReports((prevClusterReports) => {
        if (data.type === 'Report' && data.mcluster_report === prevClusterReports[0]?._id) {
          setConversations((prev) => {
            let previous_data = [...prev];
            let findIndex = previous_data.findIndex((item) => item._id === data._id);
            if (findIndex !== -1) {
              previous_data[findIndex] = data;
            } else {
              previous_data = [data, ...previous_data];
            }
            return previous_data;
          });
        }
        return prevClusterReports;
      });
    });
  };

  const getClusterData = async () => {
    try {
      setIsLoading(true);
      let response = await getSelectedClustersDetailsApi(id);
      if (response.data.type === 'success') {
        setCluster(response.data.data);
      } else {
        console.log('Cluster data get api fail', response.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Cluster data get api catach', error.message);
    }
  };

  async function fetchComments() {
    setCommentsLoading(true);
    setConversations([]);
    try {
      let res = await fetchtClusterCommentsApi(clusterReports[0]._id);
      if (res.data.type === 'success') {
        setConversations(res.data.data);
      } else {
      }
      setCommentsLoading(false);
    } catch (error) {
      setCommentsLoading(false);
    }
  }

  async function handlePostComment() {
    try {
      if (message.trim().length > 0) {
        setDisableSendBtn(true);
        let data = {
          comment: message,
          mcluster_report: clusterReports[0]._id,
          thread_id: selectedThread,
        };
        let res = await postClusterCommentApi(data);
        if (res.data.type === 'success') {
          setConversations((prev) => {
            let previous_data = [...prev];
            let findIndex = previous_data.findIndex((item) => item._id === res.data.data._id);
            if (findIndex !== -1) {
              previous_data[findIndex] = res.data.data;
            } else {
              previous_data = [res.data.data, ...previous_data];
            }
            return previous_data;
          });
          setMessage('');
          setDisableSendBtn(false);
        } else {
        }
      }
    } catch (error) {}
  }

  const handleMenuOptions = (option) => {
    setMenuModal(false);
    if (option === 'Edit cluster') {
      setShowEditClusterModal(true);
    } else if (option === 'Cluster info') {
      setClusterInfoModal(true);
    } else if (option === 'Add members') {
      setIsMembersModal(true);
    } else {
      setShowArchiveModal(true);
      // setMenuModal(false);
    }
  };

  const inviteMembers = async () => {
    let data = { cluster: cluster?._id, users: [] };
    selectedMembers.map((item) => {
      let permis = ['View'];
      if (selectedPermission === 'Can edit') {
        permis.push('Edit');
      }
      let data1 = {
        user: item,
        permissions: permis,
      };
      data.users.push(data1);
    });
    setMembersLoader(true);
    try {
      let response = await inviteClusterMembersAPi(data);
      if (response.data.type === 'success') {
        setCluster({ ...cluster, ...response.data.data });
        setSelectedMembers([]);
        setSelectedPermission(strings.canView);
        setMembersLoader(false);
        toast.success(response.data.message);
      } else {
        setMembersLoader(false);
        toast.error(response.data.message);
        console.log('invite members api failed', response.data.message);
      }
    } catch (error) {
      setMembersLoader(false);
      toast.error(error.message);
      console.log('invite members api catch', error);
    }
  };

  const updateUserPermission = async (data) => {
    setMembersLoader(true);
    let permis = ['View'];
    let permissionData = {};
    if (data.permition === 'Can edit') {
      permissionData = {
        user: data.userId,
        cluster: cluster?._id,
        permissions: [...permis, 'Edit'],
      };
    } else {
      permissionData = {
        user: data.userId,
        cluster: cluster?._id,
        permissions: ['View'],
      };
    }
    try {
      let response = await updateClusterAccessApi(permissionData);
      if (response.data.type === 'success') {
        setCluster({ ...cluster, ...response.data.data });
        // setCluster(response.data.data);
        toast.success(response.data.message);
        setMembersLoader(false);
      } else {
        toast.error(response.data.message);
        setMembersLoader(false);
        console.log('update User Cluster Access api failed', response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      setMembersLoader(false);
      console.log('update User Cluster Access api catch', error);
    }
  };

  const archiveCluster = async () => {
    setArchiveLoader(true);
    try {
      let response = await archiveClusterApi(cluster?._id);
      if (response.data.type === 'success') {
        setCluster({
          ...cluster,
          archived_at: response.data.data.archived_at,
        });
        let data = [...clusters];
        let findIndex = clusters.findIndex((clst) => clst._id === response.data.data._id);
        if (findIndex !== -1) {
          data[findIndex].archived_at = response.data.data.archived_at;
          setClusters(data);
        }
        setSortClusterBy('Archived clusters');
        setShowArchiveModal(false);
        setArchiveLoader(false);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        setArchiveLoader(false);
        console.log('archive Cluster failed', response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      setArchiveLoader(false);
      console.log('archive Cluster catch', error.message);
    }
  };

  const sortClusterReports = (option) => {
    setSortReportsBy(option);
    let data = cluster?.past_reports.filter((report) => {
      if (option === 'Last 7 days') {
        const startDate = moment().subtract(7, 'days');
        let endDate = new Date();
        if (
          moment(report.start_date).isBetween(startDate, endDate) ||
          moment(report.end_date).isBetween(startDate, endDate)
        ) {
          return report;
        }
      } else if (option === 'Last 15 days') {
        const startDate = moment().subtract(15, 'days');
        let endDate = new Date();
        if (
          moment(report.start_date).isBetween(startDate, endDate) ||
          moment(report.end_date).isBetween(startDate, endDate)
        ) {
          return report;
        }
      } else if (option === 'This month') {
        const startDate = moment().startOf('month');
        let endDate = moment(startDate).endOf('month');
        if (
          moment(report.start_date).isBetween(startDate, endDate) ||
          moment(report.end_date).isBetween(startDate, endDate)
        ) {
          return report;
        }
      } else if (option === 'last month') {
        const startDate = moment().subtract(1, 'month').startOf('month');
        let endDate = moment(startDate).endOf('month');
        if (
          moment(report.start_date).isBetween(startDate, endDate) ||
          moment(report.end_date).isBetween(startDate, endDate)
        ) {
          return report;
        }
      } else if (option === 'before last month') {
        const startDate = moment().subtract(2, 'month').startOf('month');
        let endDate = moment(startDate).endOf('month');
        if (
          moment(report.start_date).isBetween(startDate, endDate) ||
          moment(report.end_date).isBetween(startDate, endDate)
        ) {
          return report;
        }
      } else {
        return report;
      }
    });
    setSortReportsPopup(false);
    setClusterReports(data);
  };

  const filterClusterReportsByDate = () => {
    let data = cluster?.past_reports.filter((report) => {
      if (
        moment(report.start_date).isBetween(fromDate, toDate) ||
        moment(report.end_date).isBetween(fromDate, toDate)
      ) {
        return report;
      }
    });
    setSortReportsPopup(false);
    setSortReportsByDate(false);
    setSortReportsBy('Custom');
    setClusterReports(data);
    setFromDate('');
    setToDate('');
  };
  const exportXlsFile = () => {};

  // ui section

  const renderSortAndExportSection = () => {
    return (
      <div className={styles.sortExportContainerStyle} ref={setSortPopupRef}>
        <div className={styles.sortSectionWrapperStyle} onClick={() => setSortReportsPopup(true)}>
          <p className={styles.sortTextStyle}>
            {'Sort by:'}
            <span className={styles.selectedSortStyle}>{sortReportsBy}</span>
          </p>
          <Image src={chevronDownFillBlackIcon} containerStyle={styles.downIconStyle} />
          {renderSortReportsPopup()}
        </div>
        {clusterReports.length > 0 && (
          <div className={styles.exportWrapperStyle} onClick={() => exportXlsFile()}>
            <Image src={xlsBlueIcon} alt="icon" className={styles.exportIconStyle} />
            <p className={styles.exportTextStyle}>Export</p>
          </div>
        )}
      </div>
    );
  };

  const renderSortReportsPopup = () => {
    return (
      <PopOver
        show={sortReportsPopUp}
        reference={sortPopupRef}
        showOverlay={true}
        containerStyle={styles.sortModalStyle}
        onClose={() => {
          if (!sortReportsByDate) {
            setSortReportsPopup(false);
          }
        }}
      >
        <div className={styles.sortModalContainerStyle}>
          {sortReportsByDate ? (
            <div className={styles.sortByDateContainerStyle}>
              <div className={styles.sortCustomHeaderContainerStyle}>
                <div
                  className={styles.sortBackBtnContainerStyle}
                  onClick={() => setSortReportsByDate(false)}
                >
                  <Image
                    src={chevronLeftBlackIcon}
                    alt="icon"
                    containerStyle={styles.backIconStyle}
                  />
                  <p className={styles.backTextStyle}>Back </p>
                </div>
                <Button
                  title={'Done'}
                  disabled={!toDate}
                  onClick={() => filterClusterReportsByDate()}
                  btnStyle={styles.sortByDateBtnStyle}
                />
              </div>
              <div className={styles.datePickerMobileContainerStyle}>
                <div className={styles.sortOnDateContainerStyle}>
                  <DatePicker
                    type="custom"
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                    placeholder="From date"
                    reports={[cluster?.ongoing_report, ...cluster?.past_reports]}
                  />
                  <DatePicker
                    type="custom"
                    value={toDate}
                    minDate={fromDate}
                    onChange={(date) => setToDate(date)}
                    placeholder="To date"
                    reports={[cluster?.ongoing_report, ...cluster?.past_reports]}
                    disabled={!fromDate}
                    customPickerWrapperStyle={styles.pickerCustomWrapperStyle}
                  />
                </div>
                <div className={styles.sortOnDateContainerStyle}>
                  <Button
                    title={'Cancel'}
                    color={'light'}
                    btnStyle={styles.sortBtnStyle}
                    onClick={() => setSortReportsPopup(false)}
                  />
                  <Button title={'Done'} disabled={true} btnStyle={styles.sortBtnStyle} />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.sortModalIndividualSelectStyle}>
              <div
                className={styles.tabCloseBar2Style}
                onClick={() => setSortReportsPopup(false)}
              ></div>
              {reportsSortOptions.map((item, index) => {
                return (
                  <p
                    key={index}
                    onClick={() => sortClusterReports(item.value)}
                    className={
                      sortReportsBy === item
                        ? styles.sortLabelTextSelectedStyle
                        : styles.sortLabelTextStyle
                    }
                  >
                    {item.title}
                  </p>
                );
              })}
              <p className={styles.sortLabelTextStyle} onClick={() => setSortReportsByDate(true)}>
                Custom Date
              </p>
            </div>
          )}
        </div>
      </PopOver>
    );
  };

  const renderMenuOptionsModal = () => {
    return (
      <PopOver
        showOverlay={true}
        show={menuModal}
        reference={menuRef}
        onClose={() => setMenuModal(false)}
        containerStyle={styles.menuOptionsModalViewStyle}
      >
        <div className={styles.menuWrapperStyle}>
          <div className={styles.deviderStyle} />
          {clusterMenuOptions.map((item, index) => (
            <div
              key={index}
              onClick={() => handleMenuOptions(item.title)}
              className={classNames(
                styles.menuOptionViewStyle,
                (!hasAccess || cluster.archived_at) &&
                  item.title !== 'Cluster info' &&
                  styles.disableActionStyle
              )}
            >
              <div className={styles.menuOptionSubViewStyle}>
                <Avatar src={item.icon} alt="Icon" containerStyle={styles.menuOptionIconStyle} />
                <p className={styles.menuOptionTextStyle}>{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </PopOver>
    );
  };

  const renderClusterEditModalSection = () => {
    return (
      <Modal
        show={showEditClusterModal}
        showOverlay
        onClose={() => setShowEditClusterModal(false)}
        containerStyle={styles.clusterCreateModalStyle}
      >
        <ClusterCreate
          onModalClose={() => setShowEditClusterModal(false)}
          cluster={cluster}
          setCluster={setCluster}
        />
      </Modal>
    );
  };

  const renderArchiveModal = () => {
    return (
      <Modal
        show={showArchiveModal}
        showOverlay
        onClose={() => setShowArchiveModal(false)}
        containerStyle={styles.archiveModalStyle}
      >
        <div className={styles.archiveAlertViewStyle}>
          {archiveLoader && <Loader />}
          <p className={styles.archiveTextStyle}>
            Are you sure you want to archive the <span>“{cluster?.name}”</span> ?
          </p>
          <div className={styles.archiveModalBtnsStyle}>
            <Button
              title="Cancel"
              variant="light"
              onClick={() => setShowArchiveModal(false)}
              btnStyle={styles.cancelBtnStyle}
            />
            <Button
              title="Proceed"
              onClick={() => {
                archiveCluster();
              }}
              btnStyle={styles.archiveBtnStyle}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderClusterInfoModalSection = () => {
    return (
      <PopOver
        show={clusterInfoModal}
        showOverlay
        handleClickOutSide={() => setClusterInfoModal(false)}
        containerStyle={styles.clusterInfoModalStyle}
      >
        <ClusterInfo
          closeAction={() => setClusterInfoModal(false)}
          cluster={cluster}
          editClusterAction={() => {
            if (hasAccess) {
              setClusterInfoModal(false);
              setShowEditClusterModal(true);
            }
          }}
        />
      </PopOver>
    );
  };

  const renderMembersModal = () => {
    return (
      <PopOver
        show={isMembersModal}
        showOverlay={true}
        reference={membersRef}
        containerStyle={styles.membersModalStyle}
      >
        <Members
          members={cluster?.users}
          selectedMembers={selectedMembers}
          setMembers={setSelectedMembers}
          permission={selectedPermission}
          setPermission={setSelectedPermission}
          closeAction={() => setIsMembersModal(false)}
          loader={membersLoader}
          inviteAction={() => inviteMembers()}
          updatePermissionAction={(data) => updateUserPermission(data)}
          type="cluster"
        />
      </PopOver>
    );
  };

  const renderComments = () => {
    return (
      <div className={styles.commentsBlcokStyle}>
        <div
          className={styles.blockTitleWrapperStyle}
          onClick={() => setShowComments(!showComments)}
        >
          <p className={styles.blockTitleStyle}>Conversations</p>
          <Image src={chevronDownFillBlackIcon} />
        </div>
        <div className={styles.commentsContentSectionStyle}>
          <ChatInput
            setValue={(text) => {
              setMessage(text);
              setSelectedThread('');
            }}
            onInputFocus={() => setSelectedThread('')}
            value={selectedThread === '' ? message : ''}
            sendAction={() => handlePostComment()}
            disabled={disableSendBtn}
          />
          <div className={styles.commentsListStyle}>
            {conversations.length > 0 &&
              conversations.slice(0, conversationsLimit).map((conv, index) => (
                <React.Fragment key={'conv' + index}>
                  <div className={styles.commentsListItemStyle}>
                    <div className={styles.commentsListItemProfileWrapperStyle}>
                      <Avatar
                        src={conv?.user?.image}
                        label={conv?.user?.full_name}
                        containerStyle={styles.commentAvatarStyle}
                      />
                      <p className={styles.commentProfileTitleStyle}>{conv?.user?.full_name}</p>
                      <p className={styles.commentTimeStampStyle}>
                        {`• ${moment(conv.created_at).fromNow()}`}
                      </p>
                    </div>
                    <p className={styles.commentTextStyle}>{conv.comment}</p>
                    <div className={styles.replyListWrapperStyle}>
                      <div
                        onClick={() => {
                          setMessage('');
                          setSelectedThread((prev) => (prev === conv._id ? '' : conv._id));
                          setTimeout(() => {
                            document.getElementById(conv._id)?.scrollIntoView({ block: 'center' });
                          }, 100);
                        }}
                        className={styles.replyWrapperStyle}
                      >
                        <p className={styles.replyLinkStyle}>
                          {conv?.replies.length > 0
                            ? `${conv.replies.length} ${
                                conv.replies.length === 1 ? 'reply' : 'replies'
                              }`
                            : 'Reply'}
                        </p>
                        {conv?.replies.length > 0 && (
                          <Image
                            src={chevronDownFillBlueIcon}
                            alt="avatar"
                            containerStyle={classNames(
                              selectedThread === conv._id && styles.rotateIcon180Style
                            )}
                          />
                        )}
                      </div>
                      {selectedThread === conv._id && (
                        <React.Fragment>
                          {conv?.replies.length > 0 &&
                            conv.replies.map((thread, index) => (
                              <div key={'thread' + index} className={styles.replyItemStyle}>
                                <div className={styles.replyCurveStyle} />
                                <div className={styles.commentsListItemStyle}>
                                  <div className={styles.commentsListItemProfileWrapperStyle}>
                                    <Avatar
                                      src={thread?.user?.image}
                                      label={thread?.user?.full_name}
                                      containerStyle={styles.commentAvatarStyle}
                                    />
                                    <p className={styles.commentProfileTitleStyle}>
                                      {thread?.user?.full_name}
                                    </p>
                                    <p className={styles.commentTimeStampStyle}>
                                      {`• ${moment(thread.created_at).fromNow()}`}
                                    </p>
                                  </div>
                                  <p className={styles.commentTextStyle}>{thread.comment}</p>
                                </div>
                              </div>
                            ))}
                          <ChatInput
                            id={conv._id}
                            value={selectedThread === conv._id ? message : ''}
                            setValue={(text) => setMessage(text)}
                            sendAction={() => handlePostComment()}
                            disabled={disableSendBtn}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  {conversations.length !== index + 1 && <div className={styles.dividerStyle} />}
                </React.Fragment>
              ))}
            {conversations.length > conversationsLimit && (
              <span
                className={styles.moreCommentsLinkStyle}
                onClick={() => setConversationsLimit((prev) => prev + 3)}
              >
                Click to show more
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.cdMainContainerStyle}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.cdContainerStyle}>
          <div className={styles.cdHeaderBlockStyle}>
            <div className={styles.cdHeaderLeftBlockStyle}>
              {width < 480 && (
                <Image
                  src={chevronLeftBlackIcon}
                  alt="<"
                  containerStyle={styles.cdHeaderLeftBlockIconStyle}
                  onClick={() => {
                    navigate(routePaths.clusterList);
                    setShowHeader(true);
                  }}
                />
              )}
              <div className={styles.cdHeaderLeftBlockContentStyle}>
                <p className={styles.cdHeaderLeftBlockTitleStyle}>{cluster?.name}</p>
                <span className={styles.cdHeaderLeftBlockDescStyle}>
                  {cluster?.type} maintenance •{cluster?.cluster_assets?.length} Assets
                </span>
              </div>
            </div>
            <div className={styles.cdHeaderBlockRightStyle}>
              <AvatarGroup
                onClick={() => setIsMembersModal(true)}
                reference={setMembersRef}
                data={cluster?.users?.map((u) => ({ label: u.user.full_name, src: u.user.image }))}
              />
              <Image
                src={optionsVerticalGrayIcon}
                reference={setMenuRef}
                onClick={() => setMenuModal(true)}
                containerStyle={styles.optionsIconStyle}
              />
            </div>
          </div>
          <div className={styles.cdBodyBlockStyle}>
            <div className={styles.cdBodyHeaderBlockStyle}>
              {clusterTabs.map((tab, index) => {
                return (
                  <Capsule
                    key={'tab' + index}
                    label={tab}
                    containerStyle={selectedTab === tab ? styles.selectedCapsuleSyle : null}
                    onClick={() => setSelectedTab(tab)}
                  />
                );
              })}
            </div>
            {selectedTab === 'Past reports' && (
              <React.Fragment>{renderSortAndExportSection()}</React.Fragment>
            )}
            <div className={styles.cdBodyContentStyle}>
              {clusterReports.length > 0 ? (
                clusterReports.map((report, index) => (
                  <ClusterReport
                    key={'report' + index}
                    cluster={cluster}
                    setCluster={setCluster}
                    report={report}
                    show={clusterReports.length > 1}
                    selectedTab={selectedTab}
                  />
                ))
              ) : (
                <div className={styles.noDataBlockStyle}>
                  <p className={styles.noDataTitleStyle}>
                    {selectedTab === 'Ongoing'
                      ? 'There is no ongoing task'
                      : 'There are no past reports'}
                  </p>
                </div>
              )}
            </div>
            {selectedTab === 'Ongoing' && clusterReports.length > 0 && renderComments()}
          </div>
          {renderClusterEditModalSection()}
          {renderMenuOptionsModal()}
          {renderArchiveModal()}
          {renderClusterInfoModalSection()}
          {renderMembersModal()}
        </div>
      )}
    </div>
  );
};

ClusterDetails.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ClusterDetails;
