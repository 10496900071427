export const updateOverAllAssetFunc = (allAssets, newData) => {
  let data = allAssets;
  let findIndex = data.findIndex((item) => item._id === newData._id);
  if (findIndex !== -1) {
    data[findIndex] = newData;
  } else {
    data = { newData, ...data };
  }
  return data;
};

export const updateOverAllAssetWhenAssetUpdatedFunc = (allAssets, newData) => {
  let data = allAssets;
  let findIndex = data.findIndex((item) => item._id === newData._id);
  if (findIndex !== -1) {
    data[findIndex] = newData;
  }
  return data;
};

export const updateAssetFunc = (assetData, newData) => {
  let data = assetData;
  if (data && data._id === newData._id) {
    return newData;
  } else {
    return data;
  }
};

// users data update methods
export const updateOverAllUsersFunc = (allUsers, newData) => {
  let previous_data = allUsers;
  let finduserindex = previous_data.findIndex((user) => user._id === newData.assigned_to._id);
  if (finduserindex !== -1) {
    previous_data[finduserindex].asset = newData;
  }
  return previous_data;
};

export const updateOverAllUsersWhenAssetUpdatedFunc = (allUsers, newData) => {
  let previous_data = allUsers;
  if (newData.assigned_to) {
    let finduserindex = previous_data.findIndex((user) => user._id === newData.assigned_to._id);
    if (finduserindex !== -1) {
      previous_data[finduserindex].asset = {
        ...previous_data[finduserindex].asset,
        asset_status: newData.asset_status,
      };
    }
  }
  return previous_data;
};

export const updateOverAllUsersWhenAssetUnAssignFunc = (allUsers, newData) => {
  let previous_data = allUsers;
  let finduserindex = previous_data.findIndex(
    (user) => user._id === newData.assigned_data[0].assigned_to._id
  );
  if (finduserindex !== -1) {
    previous_data[finduserindex].asset = newData;
  }
  return previous_data;
};

// onle single user data update methods
export const updateUserDataWhenAssetAssignFunc = (userData, newData) => {
  let previous_data = userData;
  if (previous_data && previous_data._id === newData.assigned_to._id) {
    let find_data = previous_data.currently_assigned.find((item) => item._id === newData._id);
    if (!find_data) {
      previous_data.currently_assigned = [newData, ...previous_data.currently_assigned];
    }
  }
  return previous_data;
};

export const updateUserDataWhenAssetToAnotherFunc = (userData, newData) => {
  let previous_data = userData;
  if (previous_data && previous_data._id === newData.assigned_to._id) {
    let find_data = previous_data.currently_assigned.find((item) => item._id === newData._id);
    if (!find_data) {
      previous_data.currently_assigned = [newData, ...previous_data.currently_assigned];
    }
  }
  if (previous_data && previous_data._id !== newData.assigned_to._id) {
    let find_data = previous_data.currently_assigned.find((item) => item._id === newData._id);
    if (find_data) {
      let dataa = {
        asset: newData,
        assigned_at: find_data.assigned_at,
        assigned_to: find_data.assigned_to,
        un_assigned_at: newData.updated_at,
      };
      previous_data.previously_assigned = [dataa, ...previous_data.previously_assigned];
      let filtered = previous_data.currently_assigned.filter((item) => item._id !== newData._id);
      previous_data.currently_assigned = filtered;
    }
  }
  return previous_data;
};

export const updateUserDataWhenAssetUnAssignFunc = (userData, newData) => {
  let previous_data = userData;
  if (previous_data && previous_data._id === newData.assigned_data[0].assigned_to._id) {
    let find_data = previous_data.currently_assigned.find((item) => item._id === newData._id);
    let dataa = {
      asset: newData,
      assigned_at: find_data?.assigned_at,
      assigned_to: find_data?.assigned_to,
      un_assigned_at: newData.updated_at,
    };
    previous_data.previously_assigned = [dataa, ...previous_data.previously_assigned];
    let filtered = previous_data.currently_assigned.filter((item) => item._id !== newData._id);
    previous_data.currently_assigned = filtered;
  }
  return previous_data;
};

// clusters functins

export const updateClusterFunc = (clusters, newData) => {
  let previous_data = clusters;
  let findIndex = previous_data.findIndex((item) => item._id === newData._id);
  if (findIndex !== -1) {
    previous_data[findIndex] = { ...previous_data[findIndex], ...newData };
    return previous_data;
  } else {
    return [newData, ...previous_data];
  }
};

export const updateClustersWhenArchiveFunc = (clusters, newData) => {
  let previous_data = clusters;
  let findIndex = previous_data.findIndex((item) => item._id === newData._id);
  if (findIndex !== -1) {
    previous_data[findIndex].archived_at = newData.archived_at;
    return previous_data;
  } else {
    return [newData, ...previous_data];
  }
};

export const updateClusterMembersFunction = (clusters, newData) => {
  let previous_data = clusters;
  let findIndex = previous_data.findIndex((item) => item._id === newData._id);
  if (findIndex !== -1) {
    previous_data[findIndex].users = newData.users;
    return previous_data;
  } else {
    return [newData, ...previous_data];
  }
};

// tickets functions

export const updateAllTicketFunction = (tickets, newData) => {
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData._id);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex] = newData;
    return previous_data;
  } else {
    return [newData, ...previous_data];
  }
};

export const updateTicketsWhenForword = (tickets, newData) => {
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData._id);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex] = { ...newData };
    return previous_data;
  } else {
    return [newData, ...previous_data];
  }
};
export const updateTicketsWhenAssign = (tickets, newData) => {
  console.log('newData', newData);
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData._id);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex].ticket_status = 'Assigned';
    previous_data[ticketIndex].last_activity = newData.activity[0];
    previous_data[ticketIndex].is_high_priority = newData?.is_high_priority;
    return previous_data;
  } else {
    return [newData, ...previous_data];
  }
};

export const updateTicketsStatustoWorking = (tickets, newData) => {
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData.ticket);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex].ticket_status = 'In progress';
    previous_data[ticketIndex].last_activity = newData.activity[0];
    return previous_data;
  }
};

export const updateTicketsStatustoCompleted = (tickets, newData) => {
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData.ticket);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex].ticket_status = 'In review';
    previous_data[ticketIndex].last_activity = newData.activity[0];
    return previous_data;
  }
};

export const updateTicketsStatustoResolved = (tickets, newData) => {
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData.ticket);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex].ticket_status = 'Closed';
    previous_data[ticketIndex].last_activity = newData.activity[0];
    return previous_data;
  }
};

export const updateTicketsStatustoRevert = (tickets, newData) => {
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData._id);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex] = { ...newData, last_activity: newData.activity[0] };
    return previous_data;
  } else {
    return [newData, ...previous_data];
  }
};

export const updateTicketsStatustoCannotSolve = (tickets, newData) => {
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData.ticket);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex].ticket_status = 'In review*';
    previous_data[ticketIndex].last_activity = newData.activity[0];
    return previous_data;
  }
};

export const updateTicketsStatustoTaskUpdated = (tickets, newData) => {
  let previous_data = tickets;
  let ticketIndex = previous_data.findIndex((item) => item._id === newData.task.ticket);
  if (ticketIndex !== -1) {
    previous_data[ticketIndex].ticket_status = 'In progress';
    previous_data[ticketIndex].last_activity = newData.activity[1];
    return previous_data;
  }
};

export const updateTicketsStatustoUserInvite = (tickets, newData) => {
  let previous_data = tickets;
  let findIndex = previous_data.findIndex((item) => item._id === newData._id);
  if (findIndex !== -1) {
    previous_data[findIndex].ticket_status = newData.ticket_status;
    previous_data[findIndex].last_activity = newData.activity[0];
    previous_data[findIndex].ticket_users = newData.ticket_users;
  } else {
    let new_data = {
      _id: newData._id,
      ticket_uid: newData.ticket_uid,
      ticket_status: newData.ticket_status,
      asset: newData.asset,
      created_by: newData.created_by,
      last_activity: newData.activity[0],
      is_high_priority: newData.is_high_priority,
      ticket_users: newData.ticket_users,
    };
    previous_data = [new_data, ...previous_data];
  }
  return previous_data;
};
