import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useUserData } from 'providers/userdataprovider';
import Image from 'components/common/image';
import {
  chevronDownBlackIcon,
  chevronDownFillGrayIcon,
  chevronUpBlackIcon,
  closeIcon,
  closeLightGrayIcon,
  copyGrayIcon,
  documentIcon,
  downloadMediumDarkIcon,
  infoCircleUpFillGrayIcon,
  multiSelectIcon,
  optionsHorizentBlackIcon,
  pencilLightGrayIcon,
} from 'resources/images';
import ProgressBar from 'components/common/progress-bar';
import Input from 'components/common/input';
import Button from 'components/common/button';
import MultiSelectInput from 'components/common/multi-seleect-input';
import routePaths from 'routes/paths';
import Switch from 'components/common/switch';
import Capsule from 'components/common/capsule';
import MultiFileUpload from 'components/multi-file-upload';
import PopOver from 'components/common/pop-over';
import DatePicker from 'components/common/date-picker';
import Modal from 'components/common/modal';
import CheckBox from 'components/common/checkbox';
import {
  createClusterApi,
  getAllTemplatesApi,
  saveTemplateApi,
  updateClusterApi,
} from 'networking/api/cluster';
import Loader from 'components/common/loader';
import { useAuth } from 'providers/authprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { maintanenceFrequencyOptions, recurrencTypes, weekDays } from 'resources/data';
import { isEqual, isMatch } from 'lodash';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const ClusterCreate = (props) => {
  const { cluster, setCluster, onModalClose } = props;
  const toast = useToast();
  const { assets, users, setClusters, clusters } = useUserData();
  const { company } = useAuth();
  const { width } = useWindowDimensions();

  const defaultClusterData = {
    name: cluster ? cluster.name : '',
    description: cluster ? cluster.description : '',
    assets: [],
    users: [],
    is_similar_assets: cluster ? cluster.is_similar_assets : false,
    type: cluster ? cluster.type : 'Daily',
    start_date: cluster ? cluster.start_date : null,
    end_date: cluster ? cluster.end_date : null,
    recurrence: cluster ? cluster.recurrence : undefined,
  };

  const [clusterData, setClusterData] = useState(defaultClusterData);
  const [step, setStep] = useState(1);
  const [assetStep, setAssetStep] = useState(1);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [toolTipType, setToolTipType] = useState(null);
  const [toolTipRef, setToolTipRef] = useState(null);

  const [showChecklistCreateModal, setShowChecklistCreateModal] = useState(false);
  const [checklistCreateRef, setChecklistCreateRef] = useState(null);
  const [checklistTitle, setChecklistTitle] = useState('');
  const [checklistItemTitle, setChecklistItemTitle] = useState('');
  const [showChecklistMenuModal, setShowChecklistMenuModal] = useState(false);
  const [checklistMenuRef, setChecklistMenuRef] = useState(null);
  const [showChecklistEditModal, setShowChecklistEditModal] = useState(false);
  const [checklistEditRef, setChecklistEditRef] = useState(null);
  const [showChecklistDeleteModal, setShowChecklistDeleteModal] = useState(false);
  const [checklistDeleteRef, setChecklistDeleteRef] = useState(null);
  const [checklistItemEditTitle, setChecklistItemEditTitle] = useState('');
  const [showChecklistItemEditModal, setShowChecklistItemEditModal] = useState(false);
  const [checklistItemEditRef, setChecklistItemEditRef] = useState(null);
  const [recurrence, setRecurrence] = useState(
    clusterData
      ? clusterData.recurrence && clusterData.recurrence
      : { type: 'Days', value: '1', on: [], ends: { type: '', value: '', date: '' } }
  );
  const [showFreqModal, setShowFreqModal] = useState(false);
  const [freqRef, setFreqRef] = useState(null);
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
  const [showRecurrenceTypeModal, setShowRecurrenceTypeModal] = useState(false);
  const [recurrenceTypeRef, setRecurrenceTypeRef] = useState(null);
  const [loader, setLoader] = useState(false);
  const [choosePopUp, setChoosePopUp] = useState(false);
  const [choosePopUpType, setChoosePopUpType] = useState('');
  const [savePopUp, setSavePopUp] = useState(false);
  const [templateType, setTemplateType] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateValue, setTemplateValue] = useState();
  const [savePopUpRef, setSavePopUpRef] = useState(null);
  const [allTemplates, setAllTemplates] = useState([]);
  const [listView, setListView] = useState();
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [checklistIndex, setChecklistIndex] = useState(null);
  const [checklistItemIndex, setChecklistItemIndex] = useState(null);
  const [alreadySavedPopup, setAlreadySavedPopup] = useState(false);
  const [alreadySavedContent, setAlreadySavedContent] = useState('');
  const [endDateCheckBoxState, setEndDateCheckBoxState] = useState(false);
  const [filesUploadingState, setFilesUploadingState] = useState(false);
  const [apiError, setApiError] = useState('');
  const clusterSteps = ['Cluster info*', 'Add assets*', 'Checklist & More', 'Assign to*'];
  const recurrenceEnds = {
    on: 'On',
    after: 'After',
    never_end: 'Never end',
  };

  useEffect(() => {
    if (cluster) {
      let data = [];
      cluster.cluster_assets.map((asset) => {
        data.push({
          asset: asset.asset._id,
          asset_name: asset.asset.asset_name,
          description: asset.description,
          checklist: asset.checklist,
          attachments: asset.attachments,
        });
      });

      let users = [];
      cluster.users.map((item) => {
        users.push(item.user._id);
      });
      setClusterData({ ...clusterData, users: users, assets: data });
    }
  }, [cluster]);

  useEffect(() => {
    if (assetStep && currentAsset) {
      if (!clusterData.is_similar_assets) {
        setClusterData((prev) => {
          let previous_data = { ...prev };
          previous_data.assets[assetStep - 1] = { ...currentAsset };
          return previous_data;
        });
      } else {
        setClusterData((prev) => {
          let previous_data = { ...prev };
          previous_data.assets = previous_data.assets.map((item) => ({
            ...item,
            description: currentAsset?.description,
            checklist: currentAsset?.checklist,
            attachments: currentAsset?.attachments,
          }));
          return previous_data;
        });
      }
    }
  }, [assetStep, currentAsset, clusterData.is_similar_assets]);
  useEffect(() => {
    let prevData = clusterData.assets[assetStep - 1 || 0];
    if (clusterData.is_similar_assets) {
      setCurrentAsset({
        description: prevData?.description || '',
        checklist: prevData?.checklist || [],
        attachments: prevData?.attachments || [],
      });
    } else {
      if (prevData) {
        setCurrentAsset({
          asset: prevData?.asset || '',
          asset_name: prevData?.asset_name || '',
          description: prevData?.description || '',
          checklist: prevData?.checklist || [],
          attachments: prevData?.attachments || [],
        });
      }
    }
  }, [clusterData.is_similar_assets]);

  useEffect(() => {
    if (step === 3) {
      getSavedTemplates();
    }
  }, [step]);
  async function handleClose() {
    onModalClose();
  }

  async function handlePreviousClick() {
    if (step === 3 && !clusterData.is_similar_assets && assetStep > 1) {
      setAssetStep((prev) => {
        let nextStep = prev - 1;
        if (nextStep >= 1) {
          setCurrentAsset(clusterData.assets[nextStep - 1]);
        }
        return nextStep;
      });
    } else {
      let prevStep;
      setStep((prev) => {
        prevStep = prev;
        return prev - 1;
      });
      if (prevStep && prevStep === 4 && !clusterData.is_similar_assets) {
        setCurrentAsset(clusterData.assets[clusterData.assets.length - 1]);
        setAssetStep(clusterData.assets.length);
      }
    }
  }

  async function handleNextClick() {
    if (step === 3 && !clusterData.is_similar_assets && assetStep < clusterData.assets.length) {
      setAssetStep((prev) => {
        let nextStep = prev + 1;
        if (nextStep) {
          setCurrentAsset(clusterData.assets[nextStep - 1]);
        }
        return nextStep;
      });
    } else {
      let prevStep;
      setStep((prev) => {
        prevStep = prev;
        return prev + 1;
      });
      if (prevStep && prevStep === 2 && !clusterData.is_similar_assets) {
        setCurrentAsset(clusterData.assets[0]);
        setAssetStep(1);
      }
    }
  }

  async function handleCreateCluster() {
    // setLoader(true); /
    const users = [];
    const updatedUsers = clusterData.users.map((item) => {
      users.push({ user: item, permissions: ['View', 'Edit'] });
    });
    let updatedAssets = clusterData.assets.map((item) => {
      return {
        ...item,
        attachments: item.attachments.map((att) => att._id),
      };
    });
    let data = {
      company: company?._id,
      name: clusterData.name,
      description: clusterData.description,
      assets: updatedAssets,
      users: users,
      is_similar_assets: clusterData.is_similar_assets,
      type: clusterData.type,
      start_date: moment(clusterData.start_date).format('YYYY-MM-DD'),
      end_date: clusterData?.end_date ? moment(clusterData.end_date).format('YYYY-MM-DD') : null,
      recurrence: clusterData.type === 'Custom' ? recurrence : undefined,
    };
    if (cluster) {
      updateClusterData(data);
    } else {
      createCluster(data);
    }
  }

  const createCluster = async (data) => {
    setLoader(true);
    setApiError('');
    try {
      let response = await createClusterApi(data);
      if (response.data.type === 'success') {
        let data = [...clusters];
        setClusters([response.data.data, ...data]);
        setLoader(false);
        onModalClose();
        toast.success(response.data.message);
      } else {
        console.log('create Cluster api faile', response.data.message);
        setApiError(response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log('create Cluster api catch', error.message);
      setApiError(error.message);
      setLoader(false);
    }
  };

  const updateClusterData = async (data) => {
    let updatedClusterData = { ...data, cluster: cluster?._id };
    setLoader(true);
    setApiError('');
    try {
      let response = await updateClusterApi(updatedClusterData);
      if (response.data.type === 'success') {
        let prevCls = { ...cluster };
        if (response.data.data.type !== 'Custom') {
          setCluster({ ...prevCls, ...response.data.data, recurrence: null });
        } else {
          setCluster({ ...prevCls, ...response.data.data });
        }
        let data = [...clusters];
        let findIndex = clusters.findIndex((item) => item._id === cluster._id);
        if (findIndex !== -1) {
          data[findIndex] = {
            ...data[findIndex],
            ...response.data.data,
          };
        }
        setClusters(data);
        setLoader(false);
        toast.success(response.data.message);
        onModalClose();
      } else {
        console.log('edit Cluster api faile', response.data.message);
        setApiError(response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log('edit Cluster api catch', error.message);
      setApiError(error.message);
      setLoader(false);
    }
  };

  function isSubmitBtnDisabledFunc() {
    let value = false;
    if (step === 1 && !clusterData.name) value = true;
    if (step === 2 && clusterData.assets.length === 0) value = true;
    if (
      step === 4 &&
      (!clusterData.start_date || !clusterData.type || clusterData.users.length === 0)
    )
      value = true;
    return value;
  }

  async function handleSelectAsset(data) {
    let selectedAssets = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      let existing_cluster_asset = clusterData.assets.find((ast) => ast.asset === item);
      let asset_data = assets.find((asset) => asset._id === item);
      selectedAssets.push({
        asset: item,
        asset_name: asset_data?.asset_name,
        description: existing_cluster_asset?.description || '',
        checklist: existing_cluster_asset?.checklist || [],
        attachments: existing_cluster_asset?.attachments || [],
      });
    }
    setClusterData({
      ...clusterData,
      assets: selectedAssets,
    });
  }

  function getLeftBtnTitle() {
    let title = 'Back';
    if (step === 1) title = 'Close';
    if (step === 2) title = 'Back';
    if (step === 3) {
      if (clusterData.is_similar_assets) title = 'Back';
      else if (currentAsset?.asset === clusterData.assets[0].asset) title = 'Back';
      else title = 'Prev asset';
    }
    return title;
  }

  function getRightBtnTitle() {
    let title = 'Next step';
    if (step === 1 || step === 2) title = 'Next step';
    if (step === 3) {
      if (clusterData.is_similar_assets) title = 'Next step';
      else if (currentAsset?.asset === clusterData.assets[clusterData.assets.length - 1].asset)
        title = 'Next step';
      else title = 'Next asset';
    }
    if (step === 4) title = cluster ? 'Update' : 'Publish';
    return title;
  }

  async function handleChecklistCreate() {
    setCurrentAsset({
      ...currentAsset,
      checklist: [...currentAsset?.checklist, { title: checklistTitle, items: [] }],
    });
    setChecklistTitle('');
    setShowChecklistCreateModal(false);
  }

  async function handleChecklistItemCreate(checklistIndex) {
    let updatedChecklistData = currentAsset?.checklist.map((checklist, index) => {
      if (index === checklistIndex) {
        return {
          ...checklist,
          items: [...checklist.items, { item_title: checklistItemTitle }],
        };
      }
      return checklist;
    });
    setCurrentAsset({
      ...currentAsset,
      checklist: updatedChecklistData,
    });
    setChecklistItemTitle('');
  }

  async function handleChecklistItemRemove(index, item_index) {
    const updatedData = currentAsset?.checklist.map((checklist, i) => {
      if (i === index) {
        return {
          ...checklist,
          items: checklist.items.filter((_, i) => i !== item_index),
        };
      }
      return checklist;
    });
    setCurrentAsset({
      ...currentAsset,
      checklist: updatedData,
    });
  }

  async function handleChecklistEdit(index) {
    let updatedChecklistData = currentAsset?.checklist.map((checklist, i) => {
      if (i === index) {
        return {
          ...checklist,
          title: checklistTitle,
        };
      }
      return checklist;
    });
    setCurrentAsset({
      ...currentAsset,
      checklist: updatedChecklistData,
    });
    setChecklistTitle('');
    setShowChecklistEditModal(false);
    setShowChecklistMenuModal(false);
  }

  async function handleDeleteChecklist(index) {
    let checklist_data = [...currentAsset?.checklist];
    checklist_data = checklist_data.filter((_, i) => i !== index);
    setCurrentAsset({
      ...currentAsset,
      checklist: checklist_data,
    });
    setShowChecklistMenuModal(false);
    setShowChecklistDeleteModal(false);
  }

  async function handleChecklistItemEdit() {
    const updatedChecklistData = currentAsset?.checklist.map((checklist, i) => {
      if (i === checklistIndex) {
        return {
          ...checklist,
          items: checklist.items.map((item, j) => {
            if (j === checklistItemIndex) {
              return {
                ...item,
                item_title: checklistItemEditTitle,
              };
            }
            return item;
          }),
        };
      }
      return checklist;
    });

    setCurrentAsset({
      ...currentAsset,
      checklist: updatedChecklistData,
    });
    setChecklistIndex();
    setChecklistItemIndex();
    setChecklistItemEditTitle('');
    setShowChecklistItemEditModal(false);
  }
  const saveFunctionValidation = (value) => {
    let filtered = allTemplates.filter((item) => item.type === templateType);
    if (templateType === 'Description') {
      let findValue = filtered.find((item) => item.title === templateName);
      if (findValue) {
        setAlreadySavedPopup(true);
        setAlreadySavedContent('Description already exist in the saved templates');
        setSavePopUp(true);
      } else {
        templateSaving(templateType, templateName, templateValue);
        // setSavePopUp(true);
      }
    } else if (templateType === 'Checklist') {
      let findValue = filtered.find((item) => item.title === templateName);
      if (findValue) {
        setAlreadySavedPopup(true);
        setAlreadySavedContent('Checklist already exist in the saved templates');
        setSavePopUp(true);
      } else {
        templateSaving(templateType, templateName, templateValue);
        // setSavePopUp(true);
      }
    } else if (templateType === 'Attachments') {
      let findValue = filtered.find((item) => isEqual(value, item.value));
      if (findValue) {
        setAlreadySavedPopup(true);
        setAlreadySavedContent('Attachments already exist in the saved templates');
        setSavePopUp(true);
      } else {
        templateSaving(templateType, templateName, templateValue);
        // setSavePopUp(true);
      }
    }
  };
  const templateSaving = async (type, title, value) => {
    try {
      let response = await saveTemplateApi({
        type: type,
        title: title,
        value: value,
        company: company._id,
      });
      if (response.data.type === 'success') {
        setAllTemplates((prev) => [response.data.data, ...prev]);
        setSavePopUp(false);
        setTemplateName('');
        setTemplateValue('');
        toast.success(response.data.message);
      } else {
        console.log('save template api fails', response.data.message);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.log('save template api fails', error.message);
    }
  };

  const getSavedTemplates = async () => {
    try {
      let response = await getAllTemplatesApi(company?._id);
      if (response.data.type === 'success') {
        setAllTemplates(response.data.data);
      } else {
        console.log('save template api fails', response.data.message);
      }
    } catch (error) {
      console.log('save template api fails', error.message);
    }
  };
  const filterSavedTemplates = (type) => {
    let filtered = allTemplates.filter((item) => item.type === type);
    setFilteredTemplates(filtered);
    setChoosePopUp(true);
  };

  const isValueIncludesInTemplate = (value, type) => {
    let filteredValues = allTemplates
      .filter((item) => item.type === type)
      .map((item) => item.value);
    let isExists = false;
    if (type === 'Description') {
      if (filteredValues.includes(value)) {
        isExists = true;
      }
    }
    if (type === 'Checklist') {
      isExists = filteredValues.some((item) => isMatch(item, value));
    }
    if (type === 'Attachments') {
      isExists = filteredValues.some((item) => isEqual(item, value));
    }
    return isExists;
  };

  const renderClusterInfoSection = () => {
    return (
      <React.Fragment>
        <Input
          name="name"
          labelText={'Cluster name*'}
          inputLabelStyle={styles.labelTextStyle}
          placeholder={'Ente cluster name..'}
          value={clusterData.name}
          onChange={(e) => setClusterData({ ...clusterData, name: e.target.value })}
        />
        <Input
          type="textarea"
          name="description"
          labelText={'Cluster description (Optional)'}
          inputLabelStyle={styles.labelTextStyle}
          placeholder={'Write description here..'}
          value={clusterData.description}
          onChange={(e) => setClusterData({ ...clusterData, description: e.target.value })}
        />
      </React.Fragment>
    );
  };

  const renderClusterAssetsSection = () => {
    return (
      <React.Fragment>
        <div className={styles.assetTipViewStyle}>
          <p className={styles.assetTipDescStyle}>
            Select the assets you want to create into a cluster. If you don't have the desired
            assets registered in your company’s trakr database,&nbsp;
            <Link to={routePaths.createAsset} target="_blank" className={styles.linkStyle}>
              click here
            </Link>
          </p>
        </div>
        <MultiSelectInput
          multi
          inputLabel="Select asset(s)*"
          placeholder="Name or ID of the added asset"
          options={assets?.map((item) => ({
            label: item.asset_name,
            value: item._id,
            desc: item.asset_uid,
          }))}
          type="assets"
          selectedData={clusterData?.assets?.map((item) => item.asset)}
          setSelectedData={(data) => handleSelectAsset(data)}
          optionsPlacement={'bottom-start'}
        />
        {clusterData.assets.length > 1 && (
          <div className={styles.similarAssetViewStyle}>
            <div className={styles.similarAssetLeftWrapperStyle}>
              <p className={styles.similarAssetTitleStyle}>Selected assets are of same kind</p>
              <p className={styles.similarAssetDescStyle}>
                Enable if all the assets your added are of similar kind. For example: All laptops or
                All office chairs, etc.&nbsp;
                <Link className={styles.linkStyle}>Learn more</Link>
              </p>
            </div>
            <Switch
              checked={clusterData.is_similar_assets}
              onChange={() =>
                setClusterData({
                  ...clusterData,
                  is_similar_assets: !clusterData.is_similar_assets,
                })
              }
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderToolTipModal = () => {
    return (
      <PopOver
        show={Boolean(toolTipType)}
        reference={toolTipRef}
        onClose={() => setToolTipType(null)}
        containerStyle={styles.toolTipModalStyle}
        showOverlay
        overlayStyle={styles.zIndexOverlayStyle}
      >
        {toolTipType === 'Description' && (
          <p className={styles.toolTipTextStyle}>
            This is optional but if you want you can write descriptions for each and every asset
            added in a maintenance cluster.
          </p>
        )}
        {toolTipType === 'Checklist' && (
          <p className={styles.toolTipTextStyle}>
            Checklists (optional) are great if you have a to-do list that the person responsible for
            the cluster has to run through during a maintenance check-up.
          </p>
        )}
        {toolTipType === 'Attachments' && (
          <p className={styles.toolTipTextStyle}>
            Attachments (optional) come in handy if the maintenance of certain assets are so complex
            that they require an instruction document or manual.
          </p>
        )}
      </PopOver>
    );
  };

  const renderChecklistCreateModal = () => {
    return (
      <PopOver
        show={showChecklistCreateModal}
        showOverlay
        reference={checklistCreateRef}
        onClose={() => {
          setShowChecklistCreateModal(false);
          setChecklistTitle('');
        }}
        overlayStyle={styles.zIndexOverlayStyle}
        containerStyle={styles.checklistCreateModalStyle}
      >
        <div className={styles.checklistCreateBlockStyle}>
          <Input
            labelText="Checklist title*"
            value={checklistTitle}
            onChange={(e) => setChecklistTitle(e.target.value)}
          />
          <div className={styles.checklistCreateBtnWrapperStyle}>
            <Button
              title="Cancel"
              variant="light"
              size="md"
              onClick={() => {
                setShowChecklistCreateModal(false);
                setChecklistTitle('');
              }}
            />
            <Button
              title="Save"
              size="md"
              disabled={!checklistTitle}
              onClick={handleChecklistCreate}
            />
          </div>
        </div>
      </PopOver>
    );
  };

  const renderChecklistMenuModal = (index) => {
    return (
      <PopOver
        show={showChecklistMenuModal}
        reference={checklistMenuRef}
        containerStyle={styles.checklistMenuModalStyle}
        showOverlay
        overlayStyle={styles.zIndexOverlayStyle}
        placement="bottom-end"
        onClose={() =>
          !showChecklistEditModal && !showChecklistDeleteModal && setShowChecklistMenuModal(false)
        }
      >
        <p
          className={styles.checklistMenuItemTitleStyle}
          onClick={(e) => {
            setChecklistTitle(currentAsset?.checklist[index].title);
            setShowChecklistEditModal(true);
            setChecklistEditRef(e.target);
          }}
        >
          Edit title
        </p>
        {renderChecklistTitleEditModal(index)}
        <p
          className={styles.checklistMenuItemTitleStyle}
          onClick={(e) => {
            setShowChecklistDeleteModal(true);
            setChecklistDeleteRef(e.target);
          }}
        >
          Delete checklist
        </p>
        {renderChecklistDeleteModal(index)}
      </PopOver>
    );
  };

  const renderChecklistTitleEditModal = (index) => {
    return (
      <PopOver
        show={showChecklistEditModal}
        reference={checklistEditRef}
        placement="bottom-end"
        showOverlay
        containerStyle={styles.checklistEditModalStyle}
      >
        <div className={styles.checklistCreateBlockStyle}>
          <Input
            labelText="Edit checklist title*"
            value={checklistTitle}
            onChange={(e) => setChecklistTitle(e.target.value)}
          />
          <div className={styles.checklistCreateBtnWrapperStyle}>
            <Button
              title="Cancel"
              variant="light"
              size="md"
              onClick={() => {
                setShowChecklistEditModal(false);
                setChecklistTitle('');
                setShowChecklistMenuModal(false);
              }}
            />
            <Button
              title="Save"
              size="md"
              disabled={!checklistTitle}
              onClick={() => handleChecklistEdit(index)}
            />
          </div>
        </div>
      </PopOver>
    );
  };

  const renderChecklistDeleteModal = (index) => {
    return (
      <PopOver
        show={showChecklistDeleteModal}
        reference={checklistDeleteRef}
        placement="bottom-end"
        showOverlay
        containerStyle={styles.checklistDeleteModalStyle}
      >
        <p className={styles.deleteChecklistTitleStyle}>
          Are you sure you want to delete the "{currentAsset?.checklist[index].title}" permanently?
          <span className={styles.deleteChecklistDescStyle}>
            (Deleting checklist will also delete the items within the checklist)
          </span>
        </p>
        <div className={styles.checklistCreateBtnWrapperStyle}>
          <Button
            title="Cancel"
            variant="light"
            size="md"
            onClick={() => {
              setShowChecklistDeleteModal(false);
              setShowChecklistMenuModal(false);
            }}
          />
          <Button
            title="Yes"
            size="md"
            btnStyle={styles.dangerBtnStyle}
            onClick={() => handleDeleteChecklist(index)}
          />
        </div>
      </PopOver>
    );
  };

  const renderChecklistItemTitleEditModal = () => {
    return (
      <PopOver
        show={showChecklistItemEditModal}
        reference={checklistItemEditRef}
        placement="bottom-end"
        showOverlay
        containerStyle={styles.checklistItemEditModalStyle}
      >
        <div className={styles.checklistCreateBlockStyle}>
          <Input
            labelText="Edit checklist item title*"
            value={checklistItemEditTitle}
            onChange={(e) => setChecklistItemEditTitle(e.target.value)}
          />
          <div className={styles.checklistCreateBtnWrapperStyle}>
            <Button
              title="Cancel"
              variant="light"
              size="md"
              onClick={() => {
                setShowChecklistItemEditModal(false);
                setChecklistItemEditTitle('');
                setChecklistIndex();
                setChecklistItemIndex();
              }}
            />
            <Button
              title="Save"
              size="md"
              disabled={!checklistItemEditTitle}
              onClick={() => handleChecklistItemEdit()}
            />
          </div>
        </div>
      </PopOver>
    );
  };

  const renderClusterAssetChecklistSection = () => {
    return (
      <React.Fragment>
        <div className={styles.assetListStyle}>
          {clusterData.is_similar_assets ? (
            <Capsule label={'All added assets'} containerStyle={styles.activeCapsuleStyle} />
          ) : (
            clusterData.assets.map((item, index) => {
              return (
                <Capsule
                  key={'asset' + index}
                  label={item.asset_name}
                  containerStyle={
                    currentAsset?.asset === item.asset ? styles.activeCapsuleStyle : null
                  }
                />
              );
            })
          )}
        </div>
        {/* Description */}
        <div className={styles.assetItemBlcokStyle}>
          <div className={styles.assetItemLabelWrapperStyle}>
            <label className={styles.assetItemLabelStyle}>Description</label>
            <Image
              src={infoCircleUpFillGrayIcon}
              containerStyle={styles.assetItemLabelIconStyle}
              onClick={(e) => {
                setToolTipRef(e.target);
                setToolTipType('Description');
              }}
            />
          </div>
          <Input
            type="textarea"
            name="description"
            inputLabelStyle={styles.labelTextStyle}
            placeholder={'Asset description here..'}
            value={currentAsset?.description}
            numberOfRows={3}
            onChange={(e) => setCurrentAsset({ ...currentAsset, description: e.target.value })}
          />
          <div className={styles.assetItemBottomWrapperStyle}>
            {currentAsset?.description &&
              !isValueIncludesInTemplate(currentAsset?.description, 'Description') && (
                <Button
                  title="Save"
                  variant="gray"
                  btnStyle={styles.assetItemTemplateBtnStyle}
                  leftIcon={downloadMediumDarkIcon}
                  onClick={(e) => {
                    // saveFunctionValidation('Description', currentAsset?.description);
                    setTemplateType('Description');
                    setTemplateValue(currentAsset?.description);
                    setSavePopUpRef(e.target);
                    setSavePopUp(true);
                  }}
                />
              )}
            {templateType === 'Description' && <div>{renderSavePopup()}</div>}
            <Button
              title="Choose from saved"
              variant="gray"
              btnStyle={styles.assetItemTemplateBtnStyle}
              leftIcon={copyGrayIcon}
              onClick={() => {
                filterSavedTemplates('Description');
                setChoosePopUpType('Description');
              }}
            />
            {choosePopUpType === 'Description' && <div>{renderChooseSavedPopup()}</div>}
          </div>
        </div>
        {/* Checklist */}
        <div className={styles.assetItemBlcokStyle}>
          <div className={styles.assetItemLabelWrapperStyle}>
            <label className={styles.assetItemLabelStyle}>Checklist</label>
            <Image
              src={infoCircleUpFillGrayIcon}
              containerStyle={styles.assetItemLabelIconStyle}
              onClick={(e) => {
                setToolTipRef(e.target);
                setToolTipType('Checklist');
              }}
            />
          </div>
          {currentAsset?.checklist?.length > 0 ? (
            <div className={styles.checklistBlcokStyle}>
              {currentAsset?.checklist.map((checklist, index) => {
                return (
                  <div key={'checklist' + index} className={styles.checklistEachWrapperStyle}>
                    <div className={styles.checklistHeaderStyle}>
                      <p className={styles.checklistTitleStyle}>{checklist.title}</p>
                      <Image
                        src={optionsHorizentBlackIcon}
                        onClick={(e) => {
                          setShowChecklistMenuModal(true);
                          setChecklistMenuRef(e.target);
                        }}
                      />
                      {renderChecklistMenuModal(index)}
                    </div>
                    <div className={styles.checklistItemsWrapperStyle}>
                      {checklist?.items?.map((checklist_item, item_index) => {
                        return (
                          <div
                            key={'checklist_item' + item_index}
                            className={styles.checklistItemWrapperStyle}
                          >
                            <ul className={styles.checklistItemTitleUlStyle}>
                              <li className={styles.checklistItemTitleStyle}>
                                {checklist_item.item_title}
                              </li>
                            </ul>
                            <div className={styles.checklistItemIconsWrapperStyle}>
                              <Image
                                src={pencilLightGrayIcon}
                                containerStyle={styles.checklistItemIconStyle}
                                onClick={(e) => {
                                  setShowChecklistItemEditModal(true);
                                  setChecklistItemEditRef(e.target);
                                  setChecklistItemEditTitle(
                                    currentAsset?.checklist[index].items[item_index].item_title
                                  );
                                  setChecklistIndex(index);
                                  setChecklistItemIndex(item_index);
                                }}
                              />
                              {renderChecklistItemTitleEditModal()}
                              <Image
                                src={closeLightGrayIcon}
                                containerStyle={styles.checklistItemIconStyle}
                                onClick={() => handleChecklistItemRemove(index, item_index)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className={styles.checklistItemsCreateWrapperStyle}>
                      <Input
                        placeholder="Add checklist item"
                        inputStyle={styles.checklistItemCreateInputStyle}
                        value={checklistItemTitle}
                        onChange={(e) => setChecklistItemTitle(e.target.value)}
                      />
                      <Button
                        title="Add"
                        size="md"
                        disabled={checklistItemTitle ? false : true}
                        onClick={() => handleChecklistItemCreate(index)}
                      />
                      <Button
                        title="Clear"
                        variant="light"
                        size="md"
                        onClick={() => setChecklistItemTitle('')}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Button
              title="Add checklist"
              variant="light"
              size="md"
              leftIcon={multiSelectIcon}
              reference={setChecklistCreateRef}
              onClick={() => setShowChecklistCreateModal(true)}
            />
          )}
          {renderChecklistCreateModal()}

          <div className={styles.assetItemBottomWrapperStyle}>
            {currentAsset?.checklist?.length > 0 &&
              !isValueIncludesInTemplate(currentAsset?.checklist, 'Checklist') && (
                <Button
                  title="Save"
                  variant="gray"
                  btnStyle={styles.assetItemTemplateBtnStyle}
                  leftIcon={downloadMediumDarkIcon}
                  onClick={(e) => {
                    // saveFunctionValidation('Checklist', currentAsset?.checklist);
                    setTemplateType('Checklist');
                    setTemplateValue(currentAsset?.checklist);
                    setSavePopUpRef(e.target);
                    setSavePopUp(true);
                  }}
                />
              )}
            {templateType === 'Checklist' && <div>{renderSavePopup()}</div>}
            <Button
              title="Choose from saved"
              variant="gray"
              btnStyle={styles.assetItemTemplateBtnStyle}
              leftIcon={copyGrayIcon}
              onClick={() => {
                filterSavedTemplates('Checklist');
                setChoosePopUpType('Checklist');
              }}
            />
            {choosePopUpType === 'Checklist' && <div>{renderChooseSavedPopup()}</div>}
          </div>
        </div>
        {/* Attachments */}
        <div className={styles.assetItemBlcokStyle}>
          <div className={styles.assetItemLabelWrapperStyle}>
            <label className={styles.assetItemLabelStyle}>Attachments</label>
            <Image
              src={infoCircleUpFillGrayIcon}
              containerStyle={styles.assetItemLabelIconStyle}
              onClick={(e) => {
                setToolTipRef(e.target);
                setToolTipType('Attachments');
              }}
            />
          </div>
          <MultiFileUpload
            files={currentAsset?.attachments || []}
            setFiles={(data) =>
              setCurrentAsset({
                ...currentAsset,
                attachments: data,
              })
            }
            setFilesUploading={(e) => setFilesUploadingState(e)}
          />
          <div className={styles.assetItemBottomWrapperStyle}>
            {currentAsset?.attachments?.length > 0 &&
              !isValueIncludesInTemplate(currentAsset?.attachments, 'Attachments') && (
                <Button
                  title="Save"
                  variant="gray"
                  btnStyle={styles.assetItemTemplateBtnStyle}
                  leftIcon={downloadMediumDarkIcon}
                  onClick={(e) => {
                    // saveFunctionValidation('Attachments', currentAsset?.attachments);
                    setTemplateType('Attachments');
                    setTemplateValue(currentAsset?.attachments);
                    setSavePopUpRef(e.target);
                    setSavePopUp(true);
                  }}
                />
              )}
            {templateType === 'Attachments' && <div>{renderSavePopup()}</div>}
            <Button
              title="Choose from saved"
              variant="gray"
              btnStyle={styles.assetItemTemplateBtnStyle}
              leftIcon={copyGrayIcon}
              onClick={() => {
                filterSavedTemplates('Attachments');
                setChoosePopUpType('Attachments');
              }}
            />
            {choosePopUpType === 'Attachments' && <div>{renderChooseSavedPopup()}</div>}
          </div>
        </div>
        {renderToolTipModal()}
      </React.Fragment>
    );
  };
  const renderSavePopup = () => {
    return (
      <PopOver
        show={savePopUp}
        reference={savePopUpRef}
        showOverlay={true}
        overlayStyle={styles.overlayStyle}
        containerStyle={styles.savePopupStyle}
        placement="left-start"
      >
        {alreadySavedPopup ? (
          <div className={styles.savePopupInsideStyle}>
            <p className={styles.headerTextStyle}>{alreadySavedContent}</p>
            <div className={styles.savePopupBtnsWrapperStyle}>
              <Button
                title="Ok"
                size="md"
                variant="light"
                onClick={() => {
                  setSavePopUp(false);
                  setAlreadySavedPopup(false);
                  setAlreadySavedContent('');
                  setTemplateName('');
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.savePopupInsideStyle}>
            <Input
              placeholder={`Ex: PL ${templateType} 01`}
              labelText={'Template name*'}
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              inputStyle={styles.templateInputStyle}
            />
            <div className={styles.savePopupBtnsWrapperStyle}>
              <Button
                title="Cancel"
                size="md"
                variant="light"
                onClick={() => {
                  setSavePopUp(false);
                  setTemplateName('');
                  setTemplateValue('');
                }}
              />
              <Button
                title="Save"
                size="md"
                disabled={templateName ? false : true}
                onClick={() => {
                  if (templateType === 'Description') {
                    saveFunctionValidation(currentAsset?.description);
                  } else if (templateType === 'Checklist') {
                    saveFunctionValidation(currentAsset?.checklist);
                  } else if (templateType === 'Attachments') {
                    saveFunctionValidation(currentAsset?.attachments);
                  }
                }}
              />
            </div>
          </div>
        )}
      </PopOver>
    );
  };
  const renderChooseSavedPopup = () => {
    return (
      <Modal
        show={choosePopUp}
        showOverlay={true}
        overlayStyle={styles.overlayStyle}
        containerStyle={styles.chooseModalStyle}
        onClose={() => setChoosePopUp(false)}
        closeOnOutSideClick
      >
        <div className={styles.chooseModalInnerStyle}>
          <div className={styles.headerSectionStyle}>
            <p className={styles.headerTextStyle}>Choose {choosePopUpType} from saved</p>
            <Image
              src={closeIcon}
              onClick={() => {
                setChoosePopUp(false);
              }}
              containerStyle={styles.closeIconStyle}
            />
          </div>
          <div className={styles.templatesContainerStyle}>
            {filteredTemplates && filteredTemplates?.length > 0 ? (
              filteredTemplates?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {choosePopUpType === 'Description' && (
                      <div
                        className={
                          currentAsset?.description === item.value
                            ? styles.selectedItemStyle
                            : styles.itemStyle
                        }
                        onClick={() =>
                          setCurrentAsset({ ...currentAsset, description: item.value })
                        }
                      >
                        <div
                          className={
                            currentAsset?.description === item.value
                              ? styles.radioSelectContainerStyle
                              : styles.radioUnSelectContainerStyle
                          }
                        >
                          {currentAsset?.description === item.value && (
                            <div className={styles.radioSelectDotStyle}></div>
                          )}
                        </div>
                        <div className={styles.itemWrapperStyle}>
                          <p className={styles.itemHeaderTextStyle}>{item.title}</p>
                          <p className={styles.itemContentTextStyle}>{item.value}</p>
                        </div>
                      </div>
                    )}
                    {choosePopUpType === 'Checklist' && (
                      <div
                        className={
                          currentAsset?.checklist?.some(
                            (user) => user?.title === item?.value[0]?.title
                          )
                            ? styles.selectedItemStyle
                            : styles.itemStyle
                        }
                        onClick={() => {
                          setCurrentAsset({
                            ...currentAsset,
                            checklist: item.value ? item.value : [],
                          });
                        }}
                      >
                        <div
                          className={
                            currentAsset?.checklist?.some(
                              (user) => user?.title === item?.value[0]?.title
                            )
                              ? styles.radioSelectContainerStyle
                              : styles.radioUnSelectContainerStyle
                          }
                        >
                          {currentAsset?.checklist?.some(
                            (user) => user?.title === item?.value[0]?.title
                          ) && <div className={styles.radioSelectDotStyle}></div>}
                        </div>
                        <div className={styles.itemWrapperStyle}>
                          <p className={styles.itemHeaderTextStyle}>{item.title}</p>
                          {item?.value &&
                            item.value?.map((list_item, list_index) => {
                              return (
                                <div key={list_index} className={styles.checklistContainerStyle}>
                                  <div className={styles.checklistItemHeaderContainerStyle}>
                                    <p className={styles.checklistItemHeaderTextStyle}>
                                      {list_item.title}
                                    </p>

                                    <Image
                                      onClick={() => {
                                        if (listView) {
                                          setListView();
                                        } else {
                                          setListView(index + 1);
                                        }
                                      }}
                                      src={
                                        listView === index + 1
                                          ? chevronUpBlackIcon
                                          : chevronDownBlackIcon
                                      }
                                      containerStyle={styles.iconStyle}
                                    />
                                  </div>
                                  {listView === index + 1 && (
                                    <ul className={styles.checklistListStyle}>
                                      {list_item?.items &&
                                        list_item?.items?.map((item_title, item_index) => {
                                          return (
                                            <li
                                              key={item_index}
                                              className={styles.itemContentTextStyle}
                                            >
                                              {item_title.item_title}
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    {choosePopUpType === 'Attachments' && (
                      <div
                        className={
                          isEqual(currentAsset?.attachments, item.value)
                            ? styles.selectedItemStyle
                            : styles.itemStyle
                        }
                        onClick={() => {
                          setCurrentAsset({ ...currentAsset, attachments: item.value });
                        }}
                      >
                        <div
                          className={
                            isEqual(currentAsset?.attachments, item.value)
                              ? styles.radioSelectContainerStyle
                              : styles.radioUnSelectContainerStyle
                          }
                        >
                          {isEqual(currentAsset?.attachments, item.value) && (
                            <div className={styles.radioSelectDotStyle}></div>
                          )}
                        </div>
                        <div className={styles.itemWrapperStyle}>
                          <p className={styles.itemHeaderTextStyle}>{item?.title}</p>
                          <div className={styles.chooseFileContainerStyle}>
                            {item?.value.map((file, index) => {
                              return (
                                <div className={styles.fileStyle}>
                                  <div key={index} className={styles.fileDataViewStyle}>
                                    <p className={styles.fileTextStyle}>{file.file_name}</p>
                                    <p className={styles.fileDescStyle}>
                                      {file.file_mimetype} •
                                      {(file.file_size / 1024 / 1024).toFixed(2)} MB
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <p className={styles.itemContentTextStyle}>No templates to show</p>
            )}
          </div>
          <div className={styles.choosePopupBtnWrapperStyle}>
            <Button title="Done" onClick={() => setChoosePopUp(false)} />
          </div>
        </div>
      </Modal>
    );
  };
  const getTitle = () => {
    let msg = '';
    if (clusterData.type !== 'Custom') {
      msg += clusterData.type;
      if (clusterData.end_date) {
        msg += ` until ${moment(clusterData.end_date).format('YYYY-MM-DD')}`;
      }
    } else {
      msg += `Every ${recurrence.value} ${recurrence.type}`;
      if (clusterData.end_date) {
        msg += ` until ${moment(clusterData.end_date).format('YYYY-MM-DD')}`;
      }
    }
    return msg;
  };

  const isClusterNotStarted = () => {
    if (cluster) {
      if (moment(cluster.start_date).isAfter()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleMaintenanceFreq = (option) => {
    if (option.value !== 'Custom') {
      setShowFreqModal(false);
      setRecurrence({
        type: 'Days',
        value: '1',
        on: [],
        ends: { type: '', value: '' },
      });
      setClusterData({ ...clusterData, recurrence: undefined });
    } else {
      setShowFreqModal(false);

      if (clusterData.recurrence) {
        setRecurrence(clusterData.recurrence);
      } else {
        setRecurrence({
          type: 'Days',
          value: '1',
          on: [],
          ends: { type: '', value: '' },
        });
      }
      setShowRecurrenceModal(true);
    }
    setClusterData({ ...clusterData, end_date: null });
    setClusterData({ ...clusterData, type: option.value });
  };

  const renderFreqModal = () => {
    return (
      <PopOver
        show={showFreqModal}
        reference={freqRef}
        onClose={() => setShowFreqModal(false)}
        containerStyle={styles.freqModalStyle}
      >
        {maintanenceFrequencyOptions.map((option, index) => (
          <div
            key={index}
            className={classNames(
              styles.freqOptionStyle,
              clusterData.type === option.value && styles.activeFreqOptionStyle
            )}
            onClick={() => handleMaintenanceFreq(option)}
          >
            <p className={styles.freqOptionTitleStyle}>{option.title}</p>
            <p className={styles.freqOptionDescStyle}>{option.desc}</p>
          </div>
        ))}
      </PopOver>
    );
  };

  const rencerRecurrenceTypeModal = () => {
    return (
      <PopOver
        show={showRecurrenceTypeModal}
        showOverlay
        reference={recurrenceTypeRef}
        onClose={() => setShowRecurrenceTypeModal(false)}
        closeOnOutSideClick
        overlayStyle={styles.customOverlayStyle}
        containerStyle={styles.recurrenceTypeModalStyle}
      >
        {recurrencTypes.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setRecurrence({
                  ...recurrence,
                  type: item,
                });
                setShowRecurrenceTypeModal(false);
              }}
              className={classNames(
                styles.recurrenceTypeItemStyle,
                recurrence?.type === item && styles.recurrenceTypeActiveItemStyle
              )}
            >
              <p className={styles.recurrenceTypeItemTextStyle}>{item}</p>
            </div>
          );
        })}
      </PopOver>
    );
  };

  const handleSelectDay = (day) => {
    let dayExist = recurrence?.on?.find((item) => item === day);
    if (!dayExist) {
      setRecurrence({
        ...recurrence,
        on: [...recurrence.on, day],
      });
    } else {
      const filtered = recurrence?.on.filter((i) => i !== day);
      setRecurrence({ ...recurrence, on: filtered });
    }
  };

  const handleRecurrence = () => {
    if (recurrence && recurrence?.ends?.type) {
      if (recurrence?.ends?.type === recurrenceEnds.after) {
        let occ = recurrence?.ends?.value * recurrence.value;
        let end_date = '';
        if (recurrence.type === 'Days') {
          end_date = moment(clusterData.start_date).add(occ - 1, 'days');
        } else if (clusterData.recurrence.type === 'Weeks') {
          end_date = moment(clusterData.start_date).add(occ - 1, 'weeks');
        } else if (clusterData.recurrence.type === 'Months') {
          end_date = moment(clusterData.start_date).add(occ - 1, 'months');
        } else if (clusterData.recurrence.type === 'Years') {
          end_date = moment(clusterData.start_date).add(occ - 1, 'years');
        } else {
          end_date = '';
        }
        setClusterData({ ...clusterData, end_date });
      } else if (recurrence.ends.type === recurrenceEnds.on) {
        setClusterData({ ...clusterData, end_date: recurrence.ends.date });
      }
    }
    setClusterData({ ...clusterData, recurrence: recurrence });
    setShowRecurrenceModal(false);
  };

  const renderRecurrenceModal = () => {
    return (
      <Modal
        show={showRecurrenceModal}
        showOverlay={true}
        overlayStyle={styles.customOverlayStyle}
        containerStyle={styles.recurrenceModalStyle}
        onClose={() => setShowRecurrenceModal(false)}
        closeOnOutSideClick
      >
        <p className={styles.recurrenceTitleStyle}>Custom recurrence</p>
        <div className={styles.recurrenceBlockStyle}>
          <p className={styles.recurrenceBlockLabelStyle}>Repeat every</p>
          <div className={styles.recurrenceSubBlockStyle}>
            <Input
              type="number"
              placeholder="Number"
              min={1}
              value={recurrence?.value}
              onChange={(e) => setRecurrence({ ...recurrence, value: String(e.target.value) })}
              containerStyle={styles.numberInputWrapperStyle}
              inputStyle={styles.numberInputStyle}
            />
            <div
              className={styles.recurrenceTypeWrapperStyle}
              onClick={() => !showRecurrenceTypeModal && setShowRecurrenceTypeModal(true)}
              ref={setRecurrenceTypeRef}
            >
              <p className={styles.recurrenceTypeTextStyle}>{recurrence?.type}</p>
              <Image
                src={chevronDownFillGrayIcon}
                containerStyle={styles.selectDropIconWrapperStyle}
                imgStyle={showRecurrenceTypeModal ? styles.selectDropIconRotateStyle : ''}
              />
              {rencerRecurrenceTypeModal()}
            </div>
          </div>
        </div>
        {recurrence?.type === 'Weeks' && (
          <div className={styles.recurrenceBlockStyle}>
            <p className={styles.recurrenceBlockLabelStyle}>Repeat on</p>
            <div className={styles.recurrenceWeekDaysWrapperStyle}>
              {weekDays.map((day, index) => (
                <span
                  key={'day' + index}
                  className={classNames(
                    styles.recurrenceDayTextStyle,
                    recurrence?.on?.includes(day.value) && styles.recurrenceDayActiveTextStyle
                  )}
                  onClick={() => handleSelectDay(day.value)}
                >
                  {day.label}
                </span>
              ))}
            </div>
          </div>
        )}
        <div className={styles.recurrenceBlockStyle}>
          <p className={styles.recurrenceBlockLabelStyle}>Ends</p>
          <div className={styles.recurrenceEndsBlockStyle}>
            <div className={styles.recurrenceEndsItemStyle}>
              <CheckBox
                title="On"
                checked={recurrence?.ends?.type === recurrenceEnds.on}
                onChange={() =>
                  setRecurrence({
                    ...recurrence,
                    ends: { ...recurrence.ends, type: recurrenceEnds.on },
                  })
                }
                boxStyle={styles.checkBoxStyle}
                titleStyle={styles.checkBoxTitleStyle}
              />
              <DatePicker
                placeholder="Pick a date"
                value={recurrence?.ends?.type === recurrenceEnds.on ? recurrence.ends?.date : ''}
                onChange={(date) =>
                  setRecurrence({ ...recurrence, ends: { ...recurrence.ends, date: date } })
                }
                minDate={moment(clusterData.start_date).add(
                  recurrence?.value,
                  recurrence?.type.toLowerCase()
                )}
                disabled={recurrence?.ends?.type !== recurrenceEnds.on || !clusterData.start_date}
                containerStyle={styles.datePickerContainerStyle}
                iconStyle={styles.datePickerIconStyle}
              />
            </div>

            <div className={styles.recurrenceEndsItemStyle}>
              <CheckBox
                title="After"
                checked={recurrence?.ends?.type === recurrenceEnds.after}
                onChange={() =>
                  setRecurrence({
                    ...recurrence,
                    ends: { ...recurrence.ends, type: recurrenceEnds.after, value: 1 },
                  })
                }
                boxStyle={styles.checkBoxStyle}
                titleStyle={styles.checkBoxTitleStyle}
              />
              <Input
                type="number"
                placeholder="Number"
                min={1}
                value={recurrence?.ends?.value || ''}
                onChange={(e) =>
                  setRecurrence({
                    ...recurrence,
                    ends: { ...recurrence.ends, value: Number(e.target.value) },
                  })
                }
                disabled={recurrence?.ends?.type !== recurrenceEnds.after}
                containerStyle={styles.numberInputWrapperStyle}
                inputStyle={styles.numberInputStyle}
              />
              <span className={styles.checkBoxTitleStyle}>Occurrences</span>
            </div>
            <div className={styles.recurrenceEndsItemStyle}>
              <CheckBox
                title="Never end"
                checked={recurrence?.ends?.type === recurrenceEnds.never_end}
                onChange={() =>
                  setRecurrence({
                    ...recurrence,
                    ends: { ...recurrence.ends, type: recurrenceEnds.never_end },
                  })
                }
                boxStyle={styles.checkBoxStyle}
                titleStyle={styles.checkBoxTitleStyle}
              />
            </div>
          </div>
        </div>
        <div className={styles.recurrenceBtnWrapperStyle}>
          <Button
            title="Cancel"
            variant="light"
            size="md"
            onClick={() => setShowRecurrenceModal(false)}
          />
          <Button title="Done" size="md" onClick={handleRecurrence} disabled={!recurrence?.value} />
        </div>
      </Modal>
    );
  };

  const getMinimumDate = () => {
    if (clusterData.type === 'Weekly') {
      return moment(clusterData.start_date).add(1, 'week').subtract(1, 'second');
    } else if (clusterData.type === 'Monthly') {
      return moment(clusterData.start_date).add(1, 'month').subtract(1, 'second');
    } else if (clusterData.type === 'Yearly') {
      return moment(clusterData.start_date).add(1, 'year').subtract(1, 'second');
    } else {
      return clusterData.start_date;
    }
  };

  const renderClusterRangeAndAssignSection = () => {
    return (
      <React.Fragment>
        <DatePicker
          labelText="Start date*"
          minDate={new Date()}
          value={clusterData.start_date}
          onChange={(date) => setClusterData({ ...clusterData, start_date: date })}
          labelTextStyle={styles.labelTextStyle}
          iconStyle={styles.datePickerIconStyle}
          disabled={!isClusterNotStarted()}
        />
        <div className={styles.inputWrapperStyle}>
          <p className={styles.labelTextStyle}>Frequency of maintenance*</p>
          <div
            onClick={() => {
              !isClusterNotStarted() || !clusterData.start_date
                ? setShowFreqModal(false)
                : setShowFreqModal(true);
            }}
            className={styles.freqInputStyle}
            ref={setFreqRef}
          >
            <p className={styles.freqInputValueStyle}>{getTitle()}</p>
            <Image
              src={chevronDownFillGrayIcon}
              containerStyle={styles.selectDropIconWrapperStyle}
              imgStyle={showFreqModal ? styles.selectDropIconRotateStyle : ''}
            />
            {renderFreqModal()}
            {renderRecurrenceModal()}
          </div>
        </div>
        <DatePicker
          labelText="End date*"
          minDate={
            isClusterNotStarted() ? getMinimumDate() : moment().add(1, 'days').format('YYYY-MM-DD')
          }
          value={clusterData.end_date}
          onChange={(date) => {
            setClusterData({ ...clusterData, end_date: date });
            setEndDateCheckBoxState(false);
          }}
          labelTextStyle={styles.labelTextStyle}
          iconStyle={styles.datePickerIconStyle}
          disabled={clusterData.type === "Doesn't repeat" || !clusterData.start_date}
        />
        <CheckBox
          title="Remove end date"
          checked={endDateCheckBoxState}
          onChange={() => {
            if (clusterData.type !== "Doesn't repeat") {
              setEndDateCheckBoxState(true);
              setClusterData({ ...clusterData, end_date: null });
            }
          }}
          boxStyle={styles.checkBoxStyle}
          titleStyle={
            clusterData.type === "Doesn't repeat"
              ? styles.disableTextStyle
              : styles.checkBoxTitleStyle
          }
        />
        <MultiSelectInput
          inputLabel="Select assignee*"
          placeholder="Type email or name of the employee"
          multi
          options={users.map((user) => ({
            label: user.full_name,
            value: user._id,
          }))}
          selectedData={clusterData.users}
          setSelectedData={(data) => setClusterData({ ...clusterData, users: data })}
        />
      </React.Fragment>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {loader && <Loader />}
      <div className={styles.headerBlockStyle}>
        <div className={styles.headerTopWrapperStyle}>
          <p className={styles.titleStyle}>{cluster?._id ? 'Edit' : 'Create'} cluster</p>
          <div className={styles.headerIconsWrapperStyle}>
            {/* <Image src={''} containerStyle={styles.headerIconStyle} /> */}
            <Image src={closeIcon} containerStyle={styles.headerIconStyle} onClick={onModalClose} />
          </div>
        </div>
        <div className={styles.headerBottomWrapperStyle}>
          {clusterSteps.map((item, index) => {
            return (
              <div className={styles.stepItemStyle} key={'step' + index}>
                <p className={step >= index + 1 ? styles.subTitleStyle : styles.subTitleGrayStyle}>
                  {item}
                </p>
                <ProgressBar percentage={step >= index + 1 ? 100 : 0} />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.bodyBlockStyle}>
        {step === 1 && renderClusterInfoSection()}
        {step === 2 && renderClusterAssetsSection()}
        {step === 3 && renderClusterAssetChecklistSection()}
        {step === 4 && renderClusterRangeAndAssignSection()}
      </div>
      <div className={styles.footerBlockStyle}>
        <p className={styles.errorTextStyle}>{apiError}</p>
        <div className={styles.btnViewStyle}>
          <Button
            title={getLeftBtnTitle()}
            variant="light"
            size="md"
            onClick={step === 1 ? handleClose : handlePreviousClick}
          />
          <Button
            title={getRightBtnTitle()}
            size="md"
            disabled={
              isSubmitBtnDisabledFunc() ||
              filesUploadingState ||
              (step === 3 && !currentAsset?.checklist.every((cl) => cl.items.length > 0))
            }
            onClick={step === clusterSteps.length ? handleCreateCluster : handleNextClick}
          />
          {!clusterData.is_similar_assets && step === 3 && (
            <p className={styles.footerLengthTextStyle}>
              <span className={styles.footerLengthSpanStyle}>{assetStep}</span>/
              {clusterData.assets.length}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

ClusterCreate.propTypes = {
  onModalClose: PropTypes.func,
};

export default ClusterCreate;
