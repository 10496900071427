import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Image from 'components/common/image';
import {
  checkCircleFillBlueIcon,
  checkCircleFillGreenIcon,
  checkCornerRadiusFillBlackIcon,
  checkCornerRadiusFillGrayIcon,
  chevronDownFillBlackIcon,
  chevronDownFillBlueIcon,
  chevronRightBlackIcon,
  closeIcon,
  fileDarkIcon,
  fileFillGrayIcon,
  infoCircleFillRedIcon,
  infoCircleFillYellowIcon,
  potHoleConeBlackIcon,
  timerGrayIcon,
} from 'resources/images';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import { socket } from 'providers/socketprovider';
import { account_types } from 'resources/data';
import {
  fetchtClusterCommentsApi,
  postClusterCommentApi,
  raiseComplaintApi,
  updateAssetEntireStateApi,
  updateAssetOnHoldStatusApi,
  updatedChecklistCheckedStatusApi,
} from 'networking/api/cluster';
import Button from 'components/common/button';
import ChatInput from 'components/common/chat-input';
import Avatar from 'components/common/avatar';
import Loader from 'components/common/loader';
import Switch from 'components/common/switch';
import Modal from 'components/common/modal';
import Input from 'components/common/input';
import ImageUpload from 'components/image-upload';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const ClusterAssetReport = (props) => {
  const { cluster, setCluster, cluster_report, asset_report, onClose } = props;
  const currentDate = new Date();
  const toast = useToast();
  const { userType, user } = useAuth();
  const { width } = useWindowDimensions();
  const { setClusters, clusters } = useUserData();
  const [showComplaints, setShowComplaints] = useState(true);
  const [showAttachments, setShowAttachments] = useState(true);
  const [showDescription, setShowDescription] = useState(true);
  const [showComments, setShowComments] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [conversationsLimit, setConversationsLimit] = useState(3);
  const [actionLoading, setActionLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedThread, setSelectedThread] = useState('');
  const [reportIssueModal, setReportIssueModal] = useState(false);
  const [reportIssueLoading, setReportIssueLoading] = useState(false);
  const [disableSendBtn, setDisableSendBtn] = useState(false);
  const [complaintData, setComplaintData] = useState({
    subject: '',
    note: '',
    image: '',
    on_hold: false,
  });
  const [checklistItem, setChecklistItem] = useState('');
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [raiseComplaintErrorMsg, setRaiseComplaintErrorMsg] = useState('');
  const isReportHasTime = moment().isBetween(cluster_report.start_date, cluster_report.end_date);

  useEffect(() => {
    if (asset_report && cluster_report) {
      fetchComments();
      socketListner();
    }
    // eslint-disable-next-line
  }, [asset_report?.asset?._id, cluster_report?._id]);

  const socketListner = () => {
    socket.on('cluster-comment-create', (data) => {
      if (data.type === 'Asset' && data.mcluster_report === cluster_report?._id) {
        setConversations((prev) => {
          let previous_data = [...prev];
          let findIndex = previous_data.findIndex((item) => item._id === data._id);
          if (findIndex !== -1) {
            previous_data[findIndex] = data;
          } else {
            previous_data = [data, ...previous_data];
          }
          return previous_data;
        });
      }
    });
  };

  async function handleChecklistStateUpdate(list, list_item, checked) {
    let data = {
      cluster: cluster?._id,
      asset: asset_report?.asset._id,
      checklist_id: list._id,
      item_id: list_item._id,
      checked: !checked,
    };
    try {
      setActionLoading(true);
      let response = await updatedChecklistCheckedStatusApi(data);
      if (response.data.type === 'success') {
        if (cluster?.ongoing_report && cluster?.ongoing_report?._id === response.data.data._id) {
          setCluster({
            ...cluster,
            ongoing_report: response.data.data,
          });
        }
        setClusters((prev) => {
          let previous_data = [...prev];
          let cluster_index = previous_data.findIndex(
            (item) => item._id === response.data.data.mcluster
          );
          if (
            cluster_index !== -1 &&
            previous_data[cluster_index].last_report &&
            previous_data[cluster_index].last_report._id === response.data.data._id
          ) {
            previous_data[cluster_index].last_report = response.data.data;
          }
          return previous_data;
        });
      } else {
        console.log('markAs And Unmark Action fail', response.data.message);
      }
      setActionLoading(false);
    } catch (error) {
      setActionLoading(false);
      console.log('markAs And Unmark Action catch', error.message);
    }
  }

  // console.log('dasdasd', asset_report);

  async function handleAssetStateUpdate() {
    try {
      setActionLoading(true);
      let response = await updateAssetEntireStateApi({
        cluster: cluster._id,
        asset: asset_report?.asset._id,
        checked: !asset_report.checked,
      });
      if (response.data.type === 'success') {
        if (cluster?.ongoing_report && cluster?.ongoing_report?._id === response.data.data._id) {
          setCluster({
            ...cluster,
            ongoing_report: response.data.data,
          });
        }
        setClusters((prev) => {
          let previous_data = [...prev];
          let cluster_index = previous_data.findIndex(
            (item) => item._id === response.data.data.mcluster
          );
          if (
            cluster_index !== -1 &&
            previous_data[cluster_index].last_report &&
            previous_data[cluster_index].last_report._id === response.data.data._id
          ) {
            previous_data[cluster_index].last_report = response.data.data;
          }
          return previous_data;
        });
      } else {
        console.log(response.data.message);
      }
      setActionLoading(false);
    } catch (error) {
      setActionLoading(false);
      console.log(error);
    }
  }

  async function handleAssetHoldStateUpdate() {
    try {
      setActionLoading(true);
      let data = {
        cluster: cluster._id,
        asset: asset_report?.asset._id,
      };
      let response = await updateAssetOnHoldStatusApi(data);
      if (response.data.type === 'success') {
        setCluster((prev) => {
          let previous_data = { ...prev };
          if (previous_data?.ongoing_report) {
            let asset_index = previous_data.ongoing_report.cluster_assets.findIndex(
              (item) => item.asset._id === response.data.data.asset
            );
            if (asset_index !== -1) {
              previous_data.ongoing_report.cluster_assets[asset_index].on_hold = undefined;
            }
          }
          if (previous_data.cluster_assets.length > 0) {
            let asset_index = previous_data.cluster_assets.findIndex(
              (item) => item.asset._id === response.data.data.asset
            );
            if (asset_index !== -1) {
              previous_data.cluster_assets[asset_index].on_hold = undefined;
            }
          }
          return previous_data;
        });
        setClusters((prev) => {
          let previous_data = [...prev];
          let cluster_index = previous_data.findIndex(
            (item) => item._id === response.data.data.mcluster
          );
          if (cluster_index !== -1 && previous_data[cluster_index].last_report) {
            let asset_index = previous_data[cluster_index].last_report.cluster_assets.findIndex(
              (item) => item._id === response.data.data.asset
            );
            if (asset_index !== -1) {
              previous_data[cluster_index].last_report.cluster_assets[asset_index].on_hold =
                undefined;
            }
          }
          return previous_data;
        });
      } else {
        console.log('update OnHold Status failed', response.data.message);
      }
      setActionLoading(false);
    } catch (error) {
      setActionLoading(false);
      console.log('update Asset OnHold Status catch', error.data);
    }
  }

  async function fetchComments() {
    setCommentsLoading(true);
    setConversations([]);
    try {
      let res = await fetchtClusterCommentsApi(cluster_report._id, asset_report.asset._id);
      if (res.data.type === 'success') {
        setConversations(res.data.data);
      } else {
        console.log('comments fetch fail error', res.data.message);
      }
      setCommentsLoading(false);
    } catch (error) {
      console.log('Comments fetch catch error', error);
      setCommentsLoading(false);
    }
  }

  async function handlePostComment() {
    try {
      if (message.trim().length > 0) {
        setDisableSendBtn(true);
        let data = {
          asset: asset_report.asset._id,
          comment: message,
          mcluster_report: cluster_report._id,
          thread_id: selectedThread,
        };
        let res = await postClusterCommentApi(data);
        if (res.data.type === 'success') {
          setConversations((prev) => {
            let previous_data = [...prev];
            let findIndex = previous_data.findIndex((item) => item._id === res.data.data._id);
            if (findIndex !== -1) {
              previous_data[findIndex] = res.data.data;
            } else {
              previous_data = [res.data.data, ...previous_data];
            }
            return previous_data;
          });
          setMessage('');
          setDisableSendBtn(false);
        } else {
        }
      }
    } catch (error) {}
  }

  const resetComplaintData = () => {
    setComplaintData({
      subject: '',
      note: '',
      image: '',
      on_hold: false,
    });
    setChecklistItem('');
    setSelectedTaskId('');
  };

  const handleRaiseComplaint = async () => {
    setRaiseComplaintErrorMsg('');
    try {
      let data = {
        cluster: cluster?._id,
        asset: asset_report?.asset._id,
        checklist_item: checklistItem,
        ...complaintData,
      };
      setReportIssueLoading(true);
      let response = await raiseComplaintApi(data);
      if (response.data.type === 'success') {
        let responseData = response.data.data;
        setCluster((prev) => {
          if (prev) {
            let previous_data = { ...prev };
            if (previous_data._id === responseData.mcluster) {
              if (previous_data.ongoing_report) {
                let assetReportIndex = previous_data.ongoing_report.cluster_assets.findIndex(
                  (ca) => ca.asset._id === responseData.asset
                );
                if (assetReportIndex !== -1) {
                  if (responseData.checklist_item) {
                    let checklists =
                      previous_data.ongoing_report.cluster_assets[assetReportIndex].checklist || [];
                    let outerIndex = -1;
                    let innerIndex = -1;
                    for (let i = 0; i < checklists.length; i++) {
                      for (let j = 0; j < checklists[i].items.length; j++) {
                        if (checklists[i].items[j]._id === responseData.checklist_item) {
                          outerIndex = i;
                          innerIndex = j;
                        }
                      }
                    }
                    if (outerIndex !== -1 && innerIndex !== -1) {
                      previous_data.ongoing_report.cluster_assets[assetReportIndex].checklist[
                        outerIndex
                      ].items[innerIndex].complaint = responseData;
                    }
                  } else {
                    let isExits = previous_data.ongoing_report.cluster_assets[
                      assetReportIndex
                    ].complaints.find((com) => com._id === responseData._id);
                    if (!isExits) {
                      previous_data.ongoing_report.cluster_assets[assetReportIndex].complaints = [
                        ...previous_data.ongoing_report.cluster_assets[assetReportIndex].complaints,
                        responseData,
                      ];
                    }
                  }
                  if (responseData.on_hold) {
                    previous_data.ongoing_report.cluster_assets[assetReportIndex].on_hold =
                      responseData.on_hold;
                  }
                }
              }
              return previous_data;
            } else {
              return previous_data;
            }
          }
          return prev;
        });
        // update cluster last report of active cluster in all clusters
        let clustersData = [...clusters];
        let findClsIndex = clustersData.findIndex((item) => item._id === responseData.mcluster);
        // find asset index
        if (findClsIndex !== -1) {
          let assetIndex = clustersData[findClsIndex].last_report.cluster_assets.findIndex(
            (ast) => ast.asset === responseData.asset
          );
          /// find asset checklist index
          if (assetIndex !== -1) {
            if (responseData.checklist_item) {
              let checklists =
                clustersData[findClsIndex].last_report.cluster_assets[assetIndex].checklist || [];
              let outerIndex = -1;
              let innerIndex = -1;
              for (let i = 0; i < checklists.length; i++) {
                for (let j = 0; j < checklists[i].items.length; j++) {
                  if (checklists[i].items[j]._id === responseData.checklist_item) {
                    outerIndex = i;
                    innerIndex = j;
                  }
                }
              }
              if (outerIndex !== -1 && innerIndex !== -1) {
                clustersData[findClsIndex].last_report.cluster_assets[assetIndex].checklist[
                  outerIndex
                ].items[innerIndex].complaint = responseData;
              }
            } else {
              clustersData[findClsIndex].last_report.cluster_assets[assetIndex].complaints = [
                ...clustersData[findClsIndex].last_report.cluster_assets[assetIndex].complaints,
                responseData,
              ];
            }
            clustersData[findClsIndex].last_report.cluster_assets[assetIndex].on_hold =
              responseData?.on_hold;
          }
        }
        setClusters(clustersData);
        setReportIssueLoading(false);
        setReportIssueModal(false);
        resetComplaintData();
        toast.success(response.data.message);
      } else {
        console.log('raise Complaint api failed', response.data.message);
        setRaiseComplaintErrorMsg(response.data.message);
      }
      setReportIssueLoading(false);
    } catch (error) {
      setReportIssueLoading(false);
      setRaiseComplaintErrorMsg(error.message);
      console.log('raise Complaint api catch', error.message);
    }
  };

  const hasAccess = () => {
    if (!cluster_report) return false;
    if (cluster_report.archived_at) return false;
    if (userType === account_types.super_admin) return false;
    let userPresent = cluster.users.find((item) => item.user._id === user._id);
    if (!userPresent) return false;
    if (userPresent && userPresent.permissions.includes('Edit')) {
      return true;
    } else {
      return false;
    }
  };

  const getAssetState = () => {
    let result = getAssetTotalData(asset_report);
    let same_day = moment(currentDate).isBetween(
      cluster_report.start_date,
      cluster_report.end_date
    );
    let msg = '';
    if (asset_report?.on_hold && same_day) {
      msg += `On-hold from ${moment(asset_report.on_hold).format('MMM D')}, until resolved`;
    } else {
      if (asset_report?.checklist.length > 0) {
        if (same_day && result.checked_list_length === 0) {
          msg += `Check-up Pending on ${moment(cluster_report.start_date).format('MMM D')}`;
        } else {
          msg +=
            result.resolved_count > 0
              ? `${result.resolved_count} of ${result.complaints_count} resolved • `
              : result.complaints_count > 0
              ? `${result.complaints_count} ${
                  result.complaints_count > 1 ? 'Complaints • ' : `Complaint • `
                }`
              : '';
          msg += `${result.checked_list_length} of ${result.checklist_length} Checked ${
            asset_report?.updated_at !== null
              ? `on ${moment(asset_report?.updated_at).format('MMM D')}`
              : ''
          }`;
          msg += asset_report?.on_hold ? ' • on-hold' : '';
        }
      } else {
        if (same_day && !asset_report?.checked) {
          msg += `Check-up Pending on ${moment(cluster_report.start_date).format('MMM D')}`;
        } else {
          msg +=
            result.resolved_count > 0
              ? `${result.resolved_count} of ${result.complaints_count} resolved • `
              : result.complaints_count > 0
              ? `${result.complaints_count} ${
                  result.complaints_count > 1 ? 'Complaints • ' : `Complaint • `
                }`
              : '';
          msg += `${asset_report?.checked ? 'Checked' : 'Not checked'} ${
            asset_report?.updated_at !== null
              ? `on ${moment(asset_report?.updated_at).format('MMM D')}`
              : ''
          }`;
          msg += asset_report?.on_hold ? ' • on-hold' : '';
        }
      }
    }
    return msg;
  };

  const getReportStateIcon = () => {
    let is_checked =
      asset_report.checklist.length > 0
        ? asset_report.checklist.reduce((acc, checklist_item) => {
            return acc + checklist_item.items.filter((item) => item.checked).length;
          }, 0)
        : asset_report.checked;
    return asset_report?.on_hold
      ? potHoleConeBlackIcon
      : is_checked
      ? checkCircleFillBlueIcon
      : moment(currentDate).isBetween(cluster_report.start_date, cluster_report.end_date)
      ? timerGrayIcon
      : infoCircleFillYellowIcon;
  };

  const getAssetTotalData = (asset) => {
    let checklist_length = 0;
    let checked_list_length = 0;
    let complaints_count = 0;
    let resolved_count = 0;
    asset.checklist.forEach((list) => {
      list.items.forEach((list_item) => {
        if (
          !list_item.complaint ||
          (list_item?.complaint &&
            moment(list_item.complaint.created_at).isBetween(
              cluster_report.start_date,
              cluster_report.end_date
            )) ||
          (list_item?.complaint &&
            list_item?.complaint === 'Closed' &&
            moment(list_item.complaint.updated_at).isBetween(
              cluster_report.start_date,
              cluster_report.end_date
            ))
        ) {
          checklist_length += 1;
          if (list_item.checked) {
            checked_list_length += 1;
          }
        }
        if (list_item?.complaint) {
          complaints_count += 1;
          if (list_item.complaint.ticket_status === 'Closed') {
            resolved_count += 1;
          }
        }
      });
    });
    if (asset?.complaints?.length > 0) {
      asset?.complaints.forEach((com) => {
        complaints_count++;
        if (com?.ticket_status === 'Closed') {
          resolved_count++;
        }
      });
    }
    return {
      checklist_length,
      checked_list_length,
      complaints_count,
      resolved_count,
    };
  };

  const renderDot = () => {
    let reportCountData = getAssetTotalData(asset_report);
    let red = <div className={styles.dotRedStyle} />;
    let green = <div className={styles.dotGreenStyle} />;
    let yellow = <div className={styles.dotYellowStyle} />;
    let yellowGreen = (
      <div className={styles.dotMixedColorContainerStyle}>
        <div className={styles.dotMixedGreenStyle} />
        <div className={styles.dotMixedYellowStyle} />
      </div>
    );
    let yellowRed = (
      <div className={styles.dotMixedColorContainerStyle}>
        <div className={styles.dotMixedRedStyle} />
        <div className={styles.dotMixedYellowStyle} />
      </div>
    );
    if (reportCountData.complaints_count > 0) {
      if (reportCountData.complaints_count === reportCountData.resolved_count) {
        if (
          reportCountData.checked_list_length > 0 &&
          reportCountData.checked_list_length !== reportCountData.checklist_length
        ) {
          return yellowGreen;
        } else {
          return green;
        }
      } else {
        if (
          reportCountData.checked_list_length > 0 &&
          reportCountData.checked_list_length !== reportCountData.checklist_length
        ) {
          return yellowRed;
        } else {
          return red;
        }
      }
    } else {
      if (
        reportCountData.checked_list_length > 0 &&
        reportCountData.checked_list_length !== reportCountData.checklist_length
      ) {
        return yellow;
      }
    }
  };

  const renderReportIssue = () => {
    return (
      !isReportHasTime &&
      account_types.admin === userType &&
      hasAccess() && (
        <Button
          title="Report an issue"
          leftIcon={fileDarkIcon}
          leftIconStyle={styles.iconStyle}
          btnStyle={classNames(styles.btnStyle)}
          onClick={() => setReportIssueModal(true)}
        />
      )
    );
  };

  const renderUnholdAsset = () => {
    return (
      account_types.super_admin === userType &&
      asset_report?.on_hold && (
        <div className={styles.unholdBlockStyle}>
          <div className={styles.unholdContentWrapperStyle}>
            <p className={styles.unholdTitleStyle}>This asset is on hold</p>
            <p className={styles.unholdDescStyle}>
              Maintenance personal has kept this asset on hold from future check-ups until the
              raised complaint is resolved.
              <span className={styles.unholdLinkStyle}>&nbsp;Learn more</span>
            </p>
          </div>
          <Switch
            checked={Boolean(asset_report.on_hold)}
            onChange={() => handleAssetHoldStateUpdate()}
          />
        </div>
      )
    );
  };

  const renderComplaints = () => {
    return (
      asset_report?.complaints?.length > 0 && (
        <div className={styles.complaintsBlockStyle}>
          <div
            className={styles.blockTitleWrapperStyle}
            onClick={() => setShowComplaints(!showComplaints)}
          >
            <p className={styles.titleStyle}>Unresolved complaints</p>
            <Image
              src={chevronDownFillBlackIcon}
              containerStyle={classNames(showComplaints ? styles.rotateIconStyle : '')}
            />
          </div>
          {showComplaints && (
            <div className={styles.complaintsListStyle}>
              {asset_report.complaints.map((complaint, index) => {
                return (
                  <div key={'complaint' + index} className={styles.complaintsListItemStyle}>
                    <div className={styles.complaintsListItemContentStyle}>
                      <p className={styles.complaintsListItemTitleStyle}>{complaint.subject}</p>
                      <p className={styles.complaintsListItemSubTitleStyle}>
                        <span className={styles.complaintsListItemDescStyle}>Raised on:</span>
                        {moment(complaint.created_at).format('MMM D')}
                      </p>
                      <p className={styles.complaintsListItemSubTitleStyle}>
                        <span className={styles.complaintsListItemDescStyle}>Status:</span>
                        {complaint.ticket_status}
                      </p>
                      <p className={styles.complaintsListItemSubTitleStyle}>
                        <span className={styles.complaintsListItemDescStyle}>By:</span>
                        {complaint.created_by.full_name}
                      </p>
                    </div>
                    <Image src={chevronRightBlackIcon} containerStyle={styles.iconLgStyle} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )
    );
  };

  const renderChecklist = () => {
    return (
      asset_report?.checklist?.length > 0 && (
        <div className={styles.checklistBlockStyle}>
          <div className={styles.blockTitleWrapperStyle}>
            <p className={styles.titleStyle}>Checklist</p>
          </div>
          <div className={styles.checklistListStyle}>
            {asset_report.checklist.map((list, index) =>
              list.items.map((list_item, li_index) => {
                return (
                  <div key={'cl' + index + li_index} className={styles.checklistItemStyle}>
                    {list_item.complaint ? (
                      <Image
                        src={
                          list_item.complaint.ticket_status !== 'Closed'
                            ? infoCircleFillRedIcon
                            : isReportHasTime
                            ? list_item.checked
                              ? checkCircleFillGreenIcon
                              : checkCornerRadiusFillGrayIcon
                            : list_item.checked
                            ? checkCornerRadiusFillBlackIcon
                            : checkCornerRadiusFillGrayIcon
                        }
                      />
                    ) : (
                      <Image
                        src={
                          list_item.checked
                            ? checkCornerRadiusFillBlackIcon
                            : checkCornerRadiusFillGrayIcon
                        }
                      />
                    )}
                    <div className={styles.checklistItemRightSectionStyle}>
                      <p className={styles.subTitleStyle}>{list_item.item_title}</p>
                      <div className={styles.checklistActionWrapperStyle}>
                        <div className={styles.checklistActionBtnWrapperStyle}>
                          {!list_item.complaint ||
                          (list_item.complaint &&
                            list_item.complaint.ticket_status === 'Closed') ? (
                            isReportHasTime ? (
                              <React.Fragment>
                                <Button
                                  title={
                                    list_item.checked
                                      ? !hasAccess()
                                        ? `Inspected by ${list_item.updated_by.full_name}`
                                        : 'Unmark'
                                      : 'Mark as inspected'
                                  }
                                  btnStyle={classNames(
                                    styles.btnSmStyle,
                                    list_item.checked
                                      ? styles.lightBtnStyle
                                      : styles.lightGreenBtnStyle,
                                    !hasAccess() ? styles.disabledBtnStyle : null
                                  )}
                                  onClick={() => {
                                    hasAccess() &&
                                      isReportHasTime &&
                                      handleChecklistStateUpdate(
                                        list,
                                        list_item,
                                        list_item.checked
                                      );
                                  }}
                                  disabled={asset_report?.on_hold}
                                />
                                {!list_item.complaint && hasAccess() && list_item.checked && (
                                  <Button
                                    title={'Raise complaint'}
                                    btnStyle={classNames(
                                      styles.btnSmStyle,
                                      styles.lightRedBtnStyle
                                    )}
                                    onClick={() => {
                                      hasAccess() && isReportHasTime && setReportIssueModal(true);
                                      setChecklistItem(list_item._id);
                                      setSelectedTaskId(list._id);
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            ) : list_item.complaint ? (
                              <Button
                                title={`Complaint resolved on ${moment(
                                  list_item.complaint.updated_at
                                ).format('MMM D')}`}
                                btnStyle={classNames(styles.btnSmStyle, styles.lightGreenBtnStyle)}
                              />
                            ) : (
                              <Button
                                title={
                                  list_item.checked
                                    ? `Inspected by ${list_item.updated_by.full_name}`
                                    : 'Not inspected'
                                }
                                btnStyle={classNames(
                                  styles.btnSmStyle,
                                  list_item.checked
                                    ? styles.lightBtnStyle
                                    : styles.lightYellowBtnStyle,
                                  styles.disabledPointerBtnStyle
                                )}
                              />
                            )
                          ) : (
                            <Button
                              title={`Complaint raised by ${list_item.complaint?.created_by?.full_name}`}
                              btnStyle={classNames(
                                styles.btnSmStyle,
                                styles.lightBtnStyle,
                                styles.disabledBtnStyle
                              )}
                            />
                          )}
                        </div>
                        {(list_item.complaint || list_item.updated_at) && (
                          <p className={styles.checklistTimeStampStyle}>
                            {moment(list_item.complaint?.updated_at || list_item.updated_at).format(
                              'hh:mm a'
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {isReportHasTime && hasAccess() && (
            <Button
              title="Report other issue"
              leftIcon={fileFillGrayIcon}
              leftIconStyle={styles.iconStyle}
              btnStyle={classNames(styles.btnSmStyle, styles.lightBtnStyle, styles.folatRightStyle)}
              onClick={() => setReportIssueModal(true)}
            />
          )}
        </div>
      )
    );
  };

  const renderZeroChecklist = () => {
    return (
      asset_report?.checklist?.length === 0 && (
        <div className={styles.zeroChecklistWrapperStyle}>
          <Button
            title={asset_report?.checked ? 'Uncheck' : 'In good condition'}
            btnStyle={classNames(
              styles.zeroChecklistBtnStyle,
              !hasAccess() && styles.disabledBtnStyle
            )}
            onClick={() => hasAccess() && isReportHasTime && handleAssetStateUpdate()}
          />
          {userType !== account_types.super_admin && (
            <Button
              title="Report issue"
              variant="light"
              btnStyle={classNames(
                styles.zeroChecklistBtnStyle,
                !hasAccess() && styles.disabledBtnStyle
              )}
              onClick={() => {
                isReportHasTime &&
                  hasAccess() &&
                  !asset_report?.on_hold &&
                  setReportIssueModal(true);
              }}
            />
          )}
        </div>
      )
    );
  };

  const renderAttachments = () => {
    return (
      asset_report.attachments.length > 0 && (
        <div className={styles.attachementsBlockStyle}>
          <div
            className={styles.blockTitleWrapperStyle}
            onClick={() => setShowAttachments(!showAttachments)}
          >
            <p className={styles.titleStyle}>Attachments</p>
            <Image
              src={chevronDownFillBlackIcon}
              containerStyle={classNames(showAttachments ? styles.rotateIconStyle : '')}
            />
          </div>
          {showAttachments && (
            <div className={styles.attachmentsListStyle}>
              {asset_report.attachments.map((attachment, index) => {
                return (
                  <div
                    key={'attachment' + index}
                    className={styles.attachmentsListItemStyle}
                    onClick={() => window.open(attachment.file_url, '__blank')}
                  >
                    <p className={styles.attachmentListItemTitleStyle} title={attachment.file_name}>
                      {attachment.file_name}
                    </p>
                    <p className={styles.attachmentListItemDescStyle}>
                      {attachment.file_ext} • {(attachment.file_size / 1024 / 1024).toFixed(2)} MB
                    </p>
                    <div className={styles.attachmentTailStyle} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )
    );
  };

  const renderDescription = () => {
    return (
      asset_report.description && (
        <div className={styles.descriptionBlockStyle}>
          <div
            className={styles.blockTitleWrapperStyle}
            onClick={() => setShowDescription(!showDescription)}
          >
            <p className={styles.titleStyle}>Descrption</p>
            <Image
              src={chevronDownFillBlackIcon}
              containerStyle={classNames(showDescription ? styles.rotateIconStyle : '')}
            />
          </div>
          {showDescription && (
            <div className={styles.descriptionWrapperStyle}>
              <p className={styles.descStyle}>{asset_report.description}</p>
            </div>
          )}
        </div>
      )
    );
  };

  const renderComments = () => {
    return (
      <div className={styles.commentsBlcokStyle}>
        <div
          className={styles.blockTitleWrapperStyle}
          onClick={() => setShowComments(!showComments)}
        >
          <p className={styles.titleStyle}>Conversations</p>
          <Image src={chevronDownFillBlackIcon} />
        </div>
        <div className={styles.commentsContentSectionStyle}>
          <ChatInput
            setValue={(text) => {
              setMessage(text);
              setSelectedThread('');
            }}
            onInputFocus={() => setSelectedThread('')}
            value={selectedThread === '' ? message : ''}
            sendAction={() => handlePostComment()}
            disabled={disableSendBtn}
          />
          <div className={styles.commentsListStyle}>
            {conversations.length > 0 &&
              conversations.slice(0, conversationsLimit).map((conv, index) => (
                <React.Fragment key={'conv' + index}>
                  <div className={styles.commentsListItemStyle}>
                    <div className={styles.commentsListItemProfileWrapperStyle}>
                      <Avatar
                        src={conv?.user?.image}
                        label={conv?.user?.full_name}
                        containerStyle={styles.commentAvatarStyle}
                      />
                      <p className={styles.commentProfileTitleStyle}>{conv?.user?.full_name}</p>
                      <p className={styles.commentTimeStampStyle}>
                        {`• ${moment(conv.created_at).fromNow()}`}
                      </p>
                    </div>
                    <p className={styles.descStyle}>{conv.comment}</p>
                    <div className={styles.replyListWrapperStyle}>
                      <div
                        onClick={() => {
                          setMessage('');
                          setSelectedThread((prev) => (prev === conv._id ? '' : conv._id));
                          setTimeout(() => {
                            document.getElementById(conv._id)?.scrollIntoView({ block: 'center' });
                          }, 100);
                        }}
                        className={styles.replyWrapperStyle}
                      >
                        <p className={styles.replyLinkStyle}>
                          {conv?.replies.length > 0
                            ? `${conv.replies.length} ${
                                conv.replies.length === 1 ? 'reply' : 'replies'
                              }`
                            : 'Reply'}
                        </p>
                        {conv?.replies.length > 0 && (
                          <Image
                            src={chevronDownFillBlueIcon}
                            alt="avatar"
                            containerStyle={classNames(
                              selectedThread === conv._id && styles.rotateIcon180Style
                            )}
                          />
                        )}
                      </div>
                      {selectedThread === conv._id && (
                        <React.Fragment>
                          {conv?.replies.length > 0 &&
                            conv.replies.map((thread, index) => (
                              <div key={'thread' + index} className={styles.replyItemStyle}>
                                <div className={styles.replyCurveStyle} />
                                <div className={styles.commentsListItemStyle}>
                                  <div className={styles.commentsListItemProfileWrapperStyle}>
                                    <Avatar
                                      src={thread?.user?.image}
                                      label={thread?.user?.full_name}
                                      containerStyle={styles.commentAvatarStyle}
                                    />
                                    <p className={styles.commentProfileTitleStyle}>
                                      {thread?.user?.full_name}
                                    </p>
                                    <p className={styles.commentTimeStampStyle}>
                                      {`• ${moment(thread.created_at).fromNow()}`}
                                    </p>
                                  </div>
                                  <p className={styles.descStyle}>{thread.comment}</p>
                                </div>
                              </div>
                            ))}
                          <ChatInput
                            id={conv._id}
                            value={selectedThread === conv._id ? message : ''}
                            setValue={(text) => setMessage(text)}
                            sendAction={() => handlePostComment()}
                            disabled={disableSendBtn}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  {conversations.length !== index + 1 && <div className={styles.dividerStyle} />}
                </React.Fragment>
              ))}
            {conversations.length > conversationsLimit && (
              <span
                className={styles.moreCommentsLinkStyle}
                onClick={() => setConversationsLimit((prev) => prev + 3)}
              >
                Click to show more
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderReportIssueModal = () => {
    return (
      <Modal
        show={reportIssueModal}
        containerStyle={styles.reportModalStyle}
        showOverlay
        overlayStyle={styles.reportModalOverlayStyle}
        onClose={() => setReportIssueModal(false)}
        closeOnOutSideClick={!reportIssueLoading}
      >
        <div className={styles.reportIssueContainerStyle}>
          {reportIssueLoading && <Loader />}
          <div className={styles.reportIssueHeaderBlockStyle}>
            <div className={styles.reportIssueHeaderContentStyle}>
              <h4 className={styles.reportIssueHeaderTitleStyle}>Raise complaint</h4>
              <p className={styles.descStyle}>
                {asset_report?.asset.asset_name} ({asset_report?.asset.asset_uid})
              </p>
            </div>
            <Image
              src={closeIcon}
              onClick={() => setReportIssueModal(false)}
              containerStyle={styles.iconLgStyle}
            />
          </div>
          <div className={styles.reportIssueBodyBlcokStyle}>
            <div className={styles.reportIssueDataBlockStyle}>
              <Input
                labelText={'Complaint subject*'}
                placeholder={'Ex: Ladder became wobbly'}
                value={complaintData.subject}
                onChange={(e) => setComplaintData((prev) => ({ ...prev, subject: e.target.value }))}
              />
              <Input
                type="textarea"
                labelText={'Description or Note'}
                placeholder={'Write your message here'}
                value={complaintData.note}
                onChange={(e) => setComplaintData((prev) => ({ ...prev, note: e.target.value }))}
              />
              <ImageUpload
                label="Image of asset needing repair"
                placeholder="JPG, JPEG, or PNG (Less than 5MB)"
                image={complaintData.image}
                onSuccess={(img) => setComplaintData((prev) => ({ ...prev, image: img }))}
              />
              <div className={styles.unholdBlockStyle}>
                <div className={styles.unholdContentWrapperStyle}>
                  <p className={styles.unholdTitleStyle}>Put this asset on-hold</p>
                  <p className={styles.unholdDescStyle}>
                    Enable only if the issue regarding the asset is too serious for it to function
                    properly. By putting on-hold, this asset will be excluded from future
                    maintenance check-ups until the complaint gets resolved.
                  </p>
                </div>
                <Switch
                  checked={complaintData.on_hold}
                  onChange={() => setComplaintData((prev) => ({ ...prev, on_hold: !prev.on_hold }))}
                />
              </div>
            </div>
            <div className={styles.reportIssueBottomViewStyle}>
              <p className={styles.errorTextStyle}>{raiseComplaintErrorMsg}</p>
              <div className={styles.reportIssueBtnBlockStyle}>
                <Button
                  title={'Cancel'}
                  onClick={() => {
                    setReportIssueModal(false);
                    resetComplaintData();
                  }}
                  variant="light"
                  size={width <= 480 && 'md'}
                />
                <Button
                  title={'Proceed'}
                  onClick={() => handleRaiseComplaint()}
                  size={width <= 480 && 'md'}
                  disabled={complaintData.subject.length === 0}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {(commentsLoading || actionLoading) && <Loader />}
      <div className={styles.headerBlockStyle}>
        <div className={styles.headerLeftSectionStyle}>
          <div className={styles.headerTitleWrapperStyle}>
            <p className={styles.headingStyle}>
              {asset_report.asset.asset_name + ` (${asset_report.asset.asset_uid})`}
            </p>
            <Image src={getReportStateIcon()} containerStyle={styles.iconLgStyle}>
              {renderDot()}
            </Image>
          </div>
          <p className={styles.descStyle}>{getAssetState()}</p>
        </div>
        <Image src={closeIcon} containerStyle={styles.iconLgStyle} onClick={onClose} />
      </div>
      <div className={styles.contentBlockStyle}>
        {renderReportIssue()}
        {renderUnholdAsset()}
        {renderComplaints()}
        {renderChecklist()}
        {renderAttachments()}
        {renderDescription()}
        {renderZeroChecklist()}
        {renderComments()}
        {renderReportIssueModal()}
      </div>
    </div>
  );
};

ClusterAssetReport.propTypes = {
  cluster: PropTypes.any,
  setCluster: PropTypes.func,
  cluster_report: PropTypes.object,
  asset_report: PropTypes.object,
  onClose: PropTypes.func,
};

export default ClusterAssetReport;
